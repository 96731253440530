@import "../../_styles/variables";

.wrapper {
  height: 100%;
  padding: 10px;
  overflow: hidden;
  @media only screen and (min-width: 500px) {
    padding: 0 20px;
  }

  .contentWrapper {
    width: 100%;
    background-color: $white;
    height: 100%;
    box-shadow: 0px 2px 6px #0000000a;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    .tabs {
      display: flex;
      flex-direction: column;

      @media only screen and (min-width: 500px) {
        flex-direction: row;
      }
      div {
        //  cursor: pointer;
        margin: 18px 0;

        p {
          font-size: 1.125rem;
          font-family: $font-primary;
          margin: 0;
        }
      }

      .tabActive {
        color: $color-text;
        font-weight: bold;
        display: flex;
        padding: 5px 10px;
        text-transform: capitalize;
        border-bottom: 2px solid $border-active;
      }

      .tabInactive {
        font-weight: bold;
        display: flex;
        padding: 5px 10px;
        text-transform: capitalize;
        color: $link-default;

        &:hover {
          color: $color-text;
          border-bottom: 2px solid $border-active;
        }
      }

      @media only screen and (min-width: 992px) {
        .tabActive,
        .tabInactive {
          margin-left: 25px;
          padding: 5px 20px;
        }
      }
    }
    .content {
      @media only screen and (min-width: 992px) {
        padding: 0 30px 20px;
      }

      height: 100%;
      overflow: auto;
      padding: 10px;
    }
  }
}
