@import "../../_styles//variables";

nav {
  position: fixed;
  right: 0;
  box-shadow: 0px 2px 6px #0000000a;
  height: 90px;
  background-color: $white;
  z-index: 2 !important;
  display: flex;
  justify-content: flex-end;
  width: calc(100% - 50px);
  align-items: center;
  .region {
    margin-left: 20px;
    margin-top: -20px;
    width: 150px;
    height: 50px;
    div {
      p {
        margin: 0 !important;
      }
    }
  }

  .content {
    display: flex;
    align-items: center;
    margin: 10px;

    .notification {
      cursor: pointer;
      margin-right: 20px;
      position: relative;

      img {
        width: 15px;
      }

      .redDot {
        width: 10px;
        height: 10px;
        left: 16px;
        background-color: $border-active;
        border-radius: 50%;
        display: inline-block;
        position: absolute;
      }
    }

    .border {
      height: 20px;
      border-left: 2px solid $lightGray;
    }

    .userInfo {
      margin: 0 20px;
      display: flex;
      align-items: center;
      cursor: pointer;

      span {
        margin-right: 15px;
      }

      img {
        width: 23px;
      }
    }
  }

  @media only screen and (min-width: 992px) {
    background: $white;
    height: 90px;
    width: calc(100% - 300px);
    justify-content: flex-end;
    align-items: center;

    .region {
      width: 150px;
      height: 50px;
    }

    .content {
      .notification {
        img {
          width: initial;
        }
      }

      .border {
        height: 30px;
      }

      .userInfo {
        margin-right: 50px;

        img {
          width: 36px;
        }
      }
    }
  }
}
