@import "../../../../_styles/variables";
.wrapper {
  width: 100%;
  overflow: hidden;
  .topBar {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    background-color: $glacier;
    border-top-right-radius: 6px;
    border-top-left-radius: 6px;
    gap: 20px;
    @media only screen and (min-width: 600px) {
      flex-direction: row;
    }

    h3 {
      background-color: $glacier;
      color: $white;
      font-size: 1.188rem;
      margin: 0;
    }
    padding: 12px 20px;
    .datepicker {
      background-color: transparent;
      color: $white;
      font-size: 0.875rem;
      margin: auto;

      @media only screen and (min-width: 600px) {
        margin-left: auto;
        margin-right: 0;
      }
      &:hover {
        cursor: pointer;
      }
    }
  }

  .inventory {
    max-height: 80vh;
    overflow: auto;
    width: 100%;
    table {
      width: 100%;
      border-collapse: collapse;

      thead {
        th {
          color: $casal;
          padding: 20px 10px;
          position: sticky;
          top: 0;
          z-index: 1;
          min-width: 100px;
          white-space: nowrap;
          width: max-content;
          background-color: $white;

          &:nth-child(1) {
            text-align: left;
            padding: 20px 20px;
            min-width: 200px;
          }

          &:nth-child(2n) {
            border-left: 1px solid transparentize($gray, 0.7);
            border-right: 1px solid transparentize($gray, 0.7);
          }
        }
      }

      tbody {
        tr {
          &:nth-child(even) {
            background-color: $mercury;
            td {
              &:first-child {
                background-color: $dark-glacier;
              }
            }
          }

          &:nth-child(odd) {
            background-color: $wild-sand;
          }

          td {
            text-align: center;
            font-size: 1rem;
            padding: 10px 10px;
            white-space: nowrap;
            width: max-content;
            &:nth-of-type(even) {
              border-left: 1px solid transparentize($gray, 0.7);
              border-right: 1px solid transparentize($gray, 0.7);
            }

            &:nth-child(1) {
              text-align: left;
              padding: 10px 10px 10px 20px;
            }
          }
        }
      }
    }
  }
}
