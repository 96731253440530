@import "../../_styles//variables";

.subregionNavbar {
  position: fixed;
  right: 0;
  box-shadow: 0px 2px 6px #0000000a;
  height: 50px;
  background-color: $white;
  z-index: 2 !important;
  display: flex;
  justify-content: space-between;
  width: calc(100% - 50px);
  .header {
    padding-left: 20px;
  }

  .content {
    display: flex;
    align-items: center;
    margin: 10px;

    .border {
      height: 20px;
      border-left: 2px solid $lightGray;
      margin-left: 20px;
    }
    .subregionName {
      font-size: 16px;
      font-weight: 600;
    }
    .userInfo {
      margin: 0 20px;
      display: flex;
      align-items: center;

      span {
        margin-right: 15px;
      }

      img {
        width: 23px;
      }
    }
  }

  @media only screen and (min-width: 992px) {
    background: $white;
    height: 60px;
    width: calc(100% - 300px);
    align-items: center;

    .content {
      .border {
        height: 30px;
      }

      .userInfo {
        margin-right: 50px;

        img {
          width: 36px;
        }
      }
    }
  }
}
