.wrapper {
  h2 {
    text-align: center;
    margin-top: 0;
  }
  form {
    padding: 15px;

    display: flex;
    flex-direction: column;
    align-items: center;
    button {
      margin-bottom: 0;
    }
  }
}

.addedEmails {
  display: flex;
  flex-direction: row;
  font-size: 20px;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  border-bottom: 1px solid rgb(217, 231, 233);
  margin-bottom: 10px;
  span {
    margin-right: 5px;
  }
}
.buttons {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  gap: 10px;
}
.deleteButton {
  margin-right: 10px;
  cursor: pointer;
}
.newEmail {
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
}
