@import "../../../_styles/variables";

.wrapper {
  .checkboxDiv span,
  .workingTime span {
    color: $mine-shaft !important;
  }
  background: $white;
  position: relative;
  padding: 20px 5px;
  border-radius: 4px;
  h2 {
    color: $color-text;
    margin-bottom: 0;
    @media only screen and (max-width: 1300px) {
      margin-left: 20px;
    }
  }

  .close {
    position: absolute;
    right: 5px;
    top: 5px;
    cursor: pointer;

    img {
      width: 20px;
    }
  }

  .form {
    display: flex;
    flex-direction: column;
    padding-top: 10px;
    width: 70%;
    margin: auto;
    .center {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .inputName {
        width: 100%;
        & > div {
          width: 100%;
        }
      }
    }

    .details {
      display: flex;
      flex-direction: column;
      .left {
        height: max-content;
        width: 100%;
      }

      .middle {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        & > div {
          width: 100%;
        }
        .selectRegion {
          margin-bottom: 50px;
        }
        .channel {
          display: flex;
          align-items: center;
          gap: 20px;
          justify-content: space-between;
          img {
            width: 45px;
            height: 45px;
            object-fit: cover;
            border-radius: 50%;
          }
          .icon {
            width: 45px;
            height: 45px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            border: 1px solid $black;

            svg {
              width: 45px;
              height: 30px;
            }
          }
        }
        .generate {
          display: flex;
          align-items: center;
          gap: 20px;
          button {
            margin-bottom: 0;
          }
        }
        .checkboxDiv {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          span {
            margin-right: 10px;
          }
          .checkboxDisabled {
            label {
              input,
              span {
                cursor: not-allowed;
              }
            }
          }
        }
        .options {
          display: flex;
          flex-direction: column;
          align-items: center;
          button {
            margin-bottom: 10px;
          }
        }
      }
      .right {
        .workingTime {
          @media only screen and (min-width: 1300px) {
            width: 400px;
          }
          label {
            width: max-content;
          }
          p {
            margin: 16px 0 8px;
            @media only screen and (min-width: 992px) {
              margin: 0 0 8px;
            }
          }
          @media only screen and (min-width: 350px) {
            text-align: center;
          }
        }
        .select {
          padding: 0 40px;
        }
        .input {
          @media only screen and (min-width: 1300px) {
            padding: 0 40px;
            margin: auto;
          }
        }
      }
    }

    .submit {
      margin: auto;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      gap: 16px;
      button {
        margin-bottom: 0;
      }
      margin: 30px 0;
    }
  }

  .error {
    position: absolute;
    left: 30px;
    bottom: 10px;
    p {
      color: $terracotta;
    }
  }

  @media only screen and (min-width: 1300px) {
    padding: 10px 30px;
    box-shadow: 0px 2px 6px #0000000a;
    h2 {
      padding: 0;
    }
    .close {
      top: 15px;
      right: 15px;
    }
    .form {
      width: 100%;
      .center {
        flex-direction: row;
        width: 70%;
        margin: auto;
        .inputName {
          width: 100%;
        }
        .logoImg {
          margin-right: 50px;
        }
      }

      .details {
        margin-top: 20px;
        flex-direction: row;

        .left {
          width: 30%;
          border-right: 3px solid $input-bg-secondary;

          & > div {
            width: 95%;
          }
        }

        .middle {
          width: 35%;
          border-right: 3px solid $input-bg-secondary;
          & > div {
            width: 90%;
            margin: auto;
          }

          .options {
            flex-direction: row;
            justify-content: flex-end;
            button {
              margin-left: 10px;
            }
          }
        }
        .right {
          width: 35%;
          & > div {
            margin: auto;
          }
        }
      }

      .submit {
        margin: 20px 0 20px auto;
      }
    }
  }
}
