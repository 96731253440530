@import "../../../../_styles/variables";
.wrapper {
  display: flex;
  flex-direction: column;
  gap: 15px;
  overflow: hidden;
  height: 100%;
  position: relative;
  .options {
    width: 150px;
  }
  .datepicker {
    margin-left: auto;
    position: absolute;
    top: 13.5px;
    right: 20px;
    z-index: 1000;
  }
  .table {
    max-height: 800px;
    overflow: auto;
    border-radius: 4px;
    height: 100%;
    min-height: 300px;

    &::-webkit-scrollbar {
      width: 7px;
      height: 7px;
      padding: 5px;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 7px;
      --webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
      background: $silver;
    }

    table {
      width: 100%;
      border-collapse: collapse;

      thead {
        .topBar {
          background-color: $glacier;

          th {
            position: sticky;
            top: 0;
            padding: 12px 20px;
            div {
              margin-bottom: 0;
              z-index: 1000;
            }
            background-color: $glacier;

            &:nth-child(1) {
              color: $white;
              font-size: 1.188rem;
              margin: 0;
            }

            button {
              background-color: transparent;
              color: $white;
              font-size: 0.875rem;
              border: 1px solid $white;
              border-radius: 4px;
              padding: 9px 35px;
              margin-left: auto;
              &:hover {
                cursor: pointer;
              }
            }
          }
          .btnDisabled {
            button {
              &:hover {
                cursor: not-allowed;
              }
              border: 1px solid #183e4c;
              color: #183e4c;
            }
          }
        }

        tr {
          &:nth-child(2) {
            th {
              &:hover {
                cursor: pointer;
              }
            }
          }
          th {
            color: $casal;
            padding: 20px 10px;
            position: sticky;
            top: 63px;
            background-color: $white;
            z-index: 1;

            &:nth-child(1) {
              text-align: left;
              padding: 20px 20px;
            }
            button {
              display: inline;
              width: 20px;
              margin: 0;
            }
          }
        }
      }

      tbody {
        tr {
          &:nth-child(even) {
            background-color: $mercury;
          }

          &:nth-child(odd) {
            background-color: $wild-sand;
          }

          &.edit {
            td {
              input {
                background-color: $white;
                border-color: $glacier;
              }
            }
          }

          td {
            text-align: center;
            font-size: 1rem;
            padding: 10px 10px;
            .pointer {
              cursor: pointer;
              &:hover {
                color: $glacier;
              }
            }
            input {
              background-color: transparent;
              width: 70px;
              font-size: 1rem;
              font-family: $font-primary;
              border-color: transparent;
              padding: 0px;

              &:disabled {
                color: $black;
              }
            }
            label {
              margin-bottom: 0;
              justify-content: center;
              input {
                width: 0;
              }
            }
            &:nth-child(1) {
              text-align: left;
              padding: 10px 10px 10px 20px;
            }
            a {
              color: $black;
              &:hover {
                color: $glacier;
              }
            }
          }
        }
      }
    }
  }
  .loading {
    text-align: center;
    padding: 20px 0;
  }
  .noData {
    margin: auto;
    display: block;
    width: 200px;
    text-align: center;
    margin-top: 20px;
    padding-top: 20px;
    font-size: 1.2em;
    color: #183e4c;
  }
  .containerDetails {
    display: flex;
    flex-direction: column;
    width: 100%;

    .back {
      width: max-content;
      cursor: pointer;
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 6px;
      border-bottom: 2px solid $glacier;
      p {
        margin: 5px 0;
        font-weight: bold;
      }
      svg {
        height: 14px;
      }
    }
  }
}
