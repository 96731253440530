@import "../../../_styles/variables";

.wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;

  .header {
    margin-bottom: 20px;
    .selectDisabled {
      cursor: not-allowed;
      opacity: 0.5;
    }
    .row {
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      margin-top: 10px;
      gap: 10px;

      .selectDate {
        width: 320px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        .checkboxMonthly {
          label {
            margin-bottom: 0;
          }
        }
        .selectMonth,
        .checkboxMonthly {
          width: 115px;
          div {
            margin-bottom: 0;
          }
        }
        .selectYear {
          width: 80px;
          div {
            margin-bottom: 0;
          }
        }
        .selectPeriod {
          width: 100px;
          div {
            margin-bottom: 0;
          }
        }
        .selectDisabled {
          cursor: not-allowed;
          opacity: 0.5;
        }
      }
    }
    .icon {
      span {
        font-size: 0.875rem;
        font-family: $font-primary-medium;
        margin-left: 5px;
      }

      div {
        span:nth-of-type(1) {
          font-size: 2rem;
          color: unset;
        }

        img {
          margin-left: 15px;
        }
      }
    }

    @media only screen and (min-width: 560px) {
      .row {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
      }

      .icon {
        span {
          font-size: 1rem;
          font-family: $font-primary-medium;
        }

        div {
          display: inline-block;

          span:nth-of-type(1) {
            font-size: 2rem;
          }

          img {
            margin-left: 15px;
          }
        }
      }
    }

    @media only screen and (min-width: 992px) and (max-width: 1350px) {
      .row {
        flex-direction: column;
        align-items: flex-start;
        gap: 10px;
      }
    }

    @media only screen and (min-width: 992px) {
      padding: 10px 20px;

      .icon {
        div {
          span:nth-of-type(1) {
            font-size: 2rem;
          }
        }
      }
    }
  }
  .noMembersMsg {
    text-align: center;
    padding-top: 20px;
  }
}
