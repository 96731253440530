@import "../../../_styles/variables";

.wrapper {
  display: flex;
  flex-direction: column;
  gap: 5px;

  @media only screen and (min-width: 500px) {
    padding: 0 20px;
  }

  .left {
    padding: 20px;
    box-shadow: 0px 2px 6px #0000000a;
    border-radius: 4px;

    .header {
      display: flex;

      p {
        font-size: 1.125rem;
        font-family: $font-primary;
        cursor: pointer;
      }

      .tabActive {
        color: $color-text;
        font-weight: bold;
        display: flex;
        padding: 5px 10px;
        text-transform: capitalize;
        border-bottom: 2px solid $border-active;
        margin-left: 10px;
      }

      .tabInactive {
        font-weight: bold;
        display: flex;
        text-transform: capitalize;
        color: $link-default;
        padding: 5px 10px;
        margin-left: 10px;

        &:hover {
          color: $color-text;
          border-bottom: 2px solid $border-active;
        }
      }
    }
  }

  .right {
    box-shadow: 0px 2px 6px #0000000a;
    border-radius: 4px;
  }

  @media only screen and (min-width: 992px) {
    flex-direction: row;
    justify-content: space-between;

    .left {
      width: 60%;
      background: $white;
      overflow-x: auto;

      .header {
        .tabActive {
          margin-left: 25px;
          padding: 5px 20px;
        }

        .tabInactive {
          margin-left: 25px;
          padding: 5px 20px;
        }
      }
    }

    .right {
      width: 40%;
      background: $white;
    }
  }

  @media only screen and (min-width: 1240px) {
  }
}
