@import "../../../_styles/variables";

.wrapper {
  display: flex;
  flex-direction: column;
  background-color: white;
  border-radius: 10px;
  padding: 20px 0;
  overflow: hidden;
  align-items: center;

  button {
    padding: 10px;
  }
  .contentContainer {
    overflow: auto;
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  .graphWrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: max-content;
    @media only screen and (min-width: 1300px) {
      flex-direction: row;
    }
  }
  .graphContainer {
    padding: 10px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    font-size: 20px;
    width: 100%;
  }
  hr {
    width: 90%;
    color: $botticelli;
  }
}
