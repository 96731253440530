@import "../../../_styles/variables";

.wrapper {
  display: flex;
  flex-direction: column;

  .row {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .item {
    padding: 12px;
    margin: 0 5px 10px;
    background: $white;
    box-shadow: 0px 2px 6px #0000000a;
    border-bottom: 1px solid $lightGray;
    border-right: 1px solid $lightGray;

    border-radius: 4px;
    cursor: pointer;
    width: 100%;

    &:hover {
      background: $lightGray;
    }

    span {
      color: $color-neutral;
      font-family: $font-primary-bold;
      font-size: 1.125rem;
    }

    img {
      width: 52px;
      height: 40px;
    }

    & > div {
      display: flex;
      align-items: center;
      justify-content: space-between;
      min-height: 60px;
      h3 {
        margin: 18px 0;
      }
    }
    .loader {
      div {
        padding: 0;
      }
    }
  }

  @media only screen and (min-width: 992px) {
    .row {
      flex-direction: row;
      justify-content: space-between;

      &:first-child {
        .item:first-child {
          margin-left: 15px;
        }

        .item:last-child {
          margin-right: 15px;
        }
      }

      &:last-child {
        .item:first-child {
          margin-left: 15px;
        }

        .item:last-child {
          margin-right: 15px;
        }
      }
    }

    .item {
      border: none;
      width: 33%;
    }
  }

  @media only screen and (min-width: 1280px) {
    flex-direction: row;

    .row {
      &:first-child {
        .item:first-child {
          margin-left: 20px;
        }

        .item:last-child {
          margin-right: 5px;
        }
      }

      &:last-child {
        .item:first-child {
          margin-left: 5px;
        }

        .item:last-child {
          margin-right: 20px;
        }
      }
    }
  }
}
