@import "../../../../_styles/variables";

.wrapper {
  .title {
    font-size: 1.2rem;
    margin-bottom: 20px;
    display: flex;
    flex-direction: row;
    border: 1px solid $input-bg-secondary;
    div {
      margin-bottom: 0;
      height: unset !important;
      input {
        text-align: left;
        margin-bottom: 0;
      }
      margin-right: 10px;
    }
    .showDetails {
      padding: 0 10px;
      margin: auto;
      cursor: pointer;
      font-size: 1.2em;
    }
    .titleText {
      width: 100%;
      font-size: 0.8em;
      padding: 0 10px;
      text-align: left 5px;
    }
  }
  svg {
    cursor: pointer;
    margin: auto;
    height: 30px;
    width: 30px;
    padding-right: 10px;
  }
  .sections {
    border-bottom: 1px solid $glacier;
    margin-bottom: 20px;
    padding-bottom: 10px;
    div {
      margin-bottom: 0;
      height: unset !important;
      input {
        text-align: left;
        margin-bottom: 10px;
      }
      margin-right: 10px;
    }
    textarea {
      height: 150px;
      margin-bottom: 20px;
    }
    .subheading {
      padding-left: 20px;
    }
    .subsubheading {
      padding-left: 25px;
    }
    .baseText,
    .link {
      padding-left: 35px;
    }

    .baseText {
      margin-bottom: 10px;
    }
    .subheading,
    .subsubheading,
    .baseText,
    .link {
      display: flex;
      flex-direction: row;
      gap: 10px;

      svg {
        margin-top: 30px;
      }
    }
  }
  .error {
    text-align: center;
    color: $terracotta;
  }
  .addField {
    display: flex;
    flex-direction: row;
    gap: 20px;
  }
  .addSubSection {
    margin: auto;
    width: 140px;
    cursor: pointer;
    border-radius: 10px;
    background-color: $glacier;
    color: $white;
    padding: 0 10px 0 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    align-self: center;
    gap: 10px;
    margin-bottom: 30px;
    span {
      font-size: 1.5em;
    }
  }
  .buttons {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 20px;
  }
}
