@import "../../../_styles/variables";
.wrapper {
  label {
    font-family: $font-primary;
    min-height: 22px;
    margin-bottom: 5px;
    padding-bottom: 0;
    font-size: 0.875rem;
    color: $mine-shaft;
    padding-left: 5px;
  }
  .mapWrapper {
    height: 300px;
    border-radius: 3px;
    border: 2px solid black;
    display: flex;
    position: relative;

    .marker {
      position: absolute;
      top: calc(50% - 36px);
      left: calc(50% - 18px);
      z-index: 2;
      height: 36px;
      width: 36px;
      &:hover {
        cursor: pointer;
      }
      svg {
        height: 100%;
        width: 100%;
      }
    }
  }
}
