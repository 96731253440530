.wrapper {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  div {
    padding: 0;
  }
  p {
    color: gray;
  }
}
