@import "../../../_styles/variables";

.wrapper {
  background: $white;
  position: relative;
  border-radius: 4px;
  h2 {
    color: $color-text;
    margin-top: 0;
  }

  .close {
    position: absolute;
    right: 5px;
    top: 5px;
    cursor: pointer;

    img {
      width: 20px;
    }
  }

  .form {
    display: flex;
    flex-direction: column;
    padding: 0 30px;
    width: 100%;

    .center {
      display: flex;
      flex-direction: column;
      .selectRegion {
        margin-bottom: 50px;
        width: 100%;
      }

      .row,
      .rowSelector {
        display: flex;
        flex-direction: column;

        @media only screen and (min-width: 700px) {
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          gap: 20px;
        }
        div {
          margin-bottom: 20px;
        }
        .checkbox {
          min-width: 200px;
          margin-bottom: 0px;
          label {
            input {
              width: 0;
            }
          }
        }
      }
      .rowSelector {
        @media only screen and (min-width: 700px) {
          align-items: flex-end;
        }
        div {
          margin-bottom: 0;
          height: unset;
        }
        .selector {
          width: 100%;
        }
        input {
          height: unset;
          max-height: 36px;
        }
      }
      .checkbox {
        min-width: 200px;
        margin-bottom: 0px;
        label {
          input {
            width: 0;
          }
        }
        .datepicker {
          margin-left: 45px;
          display: flex;
          justify-content: space-between;
          flex-direction: column;
          margin-bottom: 10px;
        }
        .inputSpan {
          margin-left: 45px;
          display: flex;
          justify-content: space-between;
          flex-direction: column;
          margin-bottom: 30px;

          div {
            margin-bottom: 20px;
          }
          span {
            font-size: 1em;
            margin-top: 10px;
          }
        }
      }
    }
    .submit {
      margin: auto;
      margin-top: 20px;
    }
  }

  .error {
    p {
      color: $terracotta;
    }
  }

  @media only screen and (min-width: 1300px) {
    h2 {
      padding: 0;
    }
    .close {
      top: 15px;
      right: 15px;
    }
  }
}
