@import "../../../_styles/variables";

.wrapper {
  display: flex;
  flex-direction: column;
  gap: 24px;
  height: 100%;

  @media only screen and (min-width: 992px) {
    padding: 30px 20px;
  }

  .header {
    display: flex;
    gap: 8px;
    justify-content: space-between;
    align-items: center;
  }

  .restaurantInfos {
    text-align: center;
    img {
      width: 100px;
      height: 100px;
      border-radius: 50%;
      object-fit: cover;
    }
    form {
      .inputGroup {
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        @media only screen and (min-width: 1300px) {
          flex-direction: row;
          gap: 16px;
        }
      }
    }
    table {
      thead,
      tbody {
        th,
        td {
          text-align: left;
          &:first-child {
            padding: 10px 5px;
            width: 220px;
          }
        }
      }

      tbody {
        tr {
          td {
            max-width: 150px;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            padding-right: 10px;
            font-size: 1em;
            span {
              font-weight: bold;
              display: inline;
            }
          }

          &:hover {
            cursor: default !important;
          }
        }
      }
    }
  }
}

.noData {
  text-align: center;
  padding: 50px 0;

  span {
    font-size: 1.188rem;
    color: $link-default;
    font-family: $font-primary-bold;
  }

  @media only screen and (min-width: 992px) {
    padding: 200px 0;
  }
}
