@import "./../../../_styles/variables";

.wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;

  .header {
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-between;
    gap: 20px;
    .buttons {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      gap: 15px;
      button {
        margin-bottom: 0;
      }
      @media only screen and (min-width: 740px) {
        flex-direction: row;
      }
      @media only screen and (min-width: 992px) and (max-width: 1600px) {
        flex-direction: column;
      }
      @media only screen and (min-width: 1600px) {
        flex-direction: row;
      }
    }
    .restaurantsNumber {
      span {
        font-size: 1rem;
        font-family: $font-primary-medium;
      }

      div {
        span:nth-of-type(1) {
          font-size: 3rem;
        }

        img {
          margin-left: 15px;
          height: 50px;
        }
      }
    }

    @media only screen and (min-width: 500px) {
      flex-direction: row;
    }

    @media only screen and (min-width: 992px) {
      padding-left: 20px;
      padding-right: 20px;
    }

    @media only screen and (min-width: 992px) and (max-width: 1150px) {
      flex-direction: column-reverse;
    }
  }
  .tabs {
    display: flex;
    margin-bottom: 20px;

    p {
      font-size: 1.125rem;
      cursor: pointer;
      font-family: $font-primary;
    }

    .tabActive {
      color: $color-text;
      font-weight: bold;
      display: flex;
      padding: 5px 10px;
      text-transform: capitalize;
      border-bottom: 2px solid $border-active;
      margin-left: 10px;
    }

    .tabInactive {
      font-weight: bold;
      display: flex;
      padding: 5px 10px;
      text-transform: capitalize;
      color: $link-default;
      margin-left: 10px;

      &:hover {
        color: $color-text;
        border-bottom: 2px solid $border-active;
      }
    }
  }
  h2 {
    color: $mine-shaft;
    margin: 20px 0 0;

    @media only screen and (min-width: 992px) {
      padding-left: 20px;
      padding-right: 20px;
    }
  }

  .searchAndFilter {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 16px;
    margin: 30px 0;
    align-items: center;

    .selector {
      width: 110px;
    }
    .checkboxWrapper {
      display: flex;
      flex-direction: column;

      label {
        margin-bottom: 0;
      }

      @media only screen and (min-width: 400px) {
        flex-direction: row;
      }
    }

    .disabledCheckbox {
      @extend .checkboxWrapper;

      label {
        input,
        span {
          cursor: not-allowed;
        }
      }
    }
    padding-left: 20px;
    padding-right: 20px;

    @media only screen and (max-width: 992px) and (min-width: 650px) {
      flex-direction: row;
    }

    @media only screen and (max-width: 1400px) and (min-width: 992px) {
      flex-direction: column;
    }

    @media only screen and (min-width: 1400px) {
      flex-direction: row;
    }
  }
}

.tableWrapper {
  min-height: 200px;

  &::-webkit-scrollbar {
    width: 7px;
    height: 7px;
    padding: 5px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 7px;
    --webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
    background: $casper;
  }
}

.pagination {
  padding: 15px;
  margin-top: auto;
}
