@import "../../../_styles/variables";

.tableWrapper {
  .noCleanerMsg {
    text-align: center;
  }

  &::-webkit-scrollbar {
    width: 7px;
    height: 7px;
    padding: 5px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 7px;
    --webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
    background: $casper;
  }

  overflow: auto;
  height: 100%;
  max-height: 400px;

  @media only screen and (min-width: 992px) {
    max-height: unset;
  }

  table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0;

    thead th {
      position: sticky;
      top: 0;
      color: $gray;
      font-size: 0.875rem;
      font-weight: normal;
      border-top: 1px solid transparentize($gray, 0.7);
      border-bottom: 1px solid transparentize($gray, 0.7);
      padding: 8px 14px;
      white-space: nowrap;
      cursor: pointer;
      background-color: $white;

      &:first-of-type {
        min-width: 50%;

        @media only screen and (min-width: 500px) {
          padding: 12px 4px 12px 36px;
        }
      }

      &:nth-child(1) {
        border-right: 1px solid transparentize($gray, 0.7);
      }

      button {
        display: inline;
        width: 20px;
        margin: 0;
      }
    }

    tbody {
      tr {
        td {
          text-align: center;
          color: $mine-shaft;
          border-bottom: 1px solid transparentize($gray, 0.7);
          padding: 8px 24px;
          white-space: nowrap;

          &:first-child {
            border-right: 1px solid transparentize($gray, 0.7);
          }

          @media only screen and (min-width: 500px) {
            padding: 12px 36px;
          }
        }

        &:hover {
          background-color: $botticelli;
          cursor: pointer;
        }
      }

      .selected {
        background-color: $botticelli;
      }
    }
  }
}
