@import "../../../../_styles/variables";

.wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;

  .buttonsWrapper {
    display: flex;
    justify-content: space-between;

    button {
      margin: 0px;
    }
  }

  .paymentTitle {
    padding: 0;
    margin-top: 20px;
    text-align: center;
  }

  .containerButtons {
    margin-top: 45px;
    display: flex;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    align-items: center;
    background-color: $wild-sand;
    width: max-content;
    height: max-content;
    margin-top: 35px;
    border-radius: 15px;
    @media only screen and (min-width: 400px) {
      flex-direction: row;
    }
    @media only screen and (min-width: 992px) {
      flex-direction: column;
    }
    @media only screen and (min-width: 1130px) {
      flex-direction: row;
    }
  }

  .options {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-top: 8px;

    &:first-child {
      margin-left: auto;
    }
  }

  .retire {
    color: $casal;
    font-family: $font-primary-bold;
    padding-right: 33px;
    text-align: right;
    margin-bottom: 0;
  }
}
