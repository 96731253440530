@import "../../_styles/variables";

.wrapper {
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0;
    /* <-- Apparently some margin are still there even though it's hidden */
  }

  input[type="number"] {
    -moz-appearance: textfield;
    /* Firefox */
  }

  .input {
    border-color: $white;
    max-height: 45px;
    width: 100%;
    border-radius: 10px;
    padding: 15px;
    border: none;

    &::placeholder {
      color: $black;
    }

    &:disabled {
      cursor: not-allowed;
    }

    &:active {
      outline: none;
      background-color: $botticelli !important;
    }

    &:focus {
      outline: none;
    }

    &[type="password"] {
      letter-spacing: 1px;
    }

    &[type="password"]::placeholder {
      letter-spacing: initial;
    }
  }

  .desc {
    display: flex;
    margin-bottom: 10px;
  }

  span {
    color: $terracotta !important;
  }

  label {
    text-align: left;
    display: block;
    padding-left: 5px;
    color: $black;
    padding-bottom: 15px;
  }

  img {
    position: absolute;
    right: 0;
    cursor: pointer;
  }
}

.inputDefault {
  @extend .wrapper;
  margin-bottom: 30px;
}

.inputLogin {
  @extend .wrapper;
  margin-bottom: 15px;
  margin-top: 0px;
  border-radius: 10px;
  position: relative;

  input {
    font-size: 0.8rem;
    font-family: $font-primary;
    background-color: $botticelli;
    font-weight: 500;
    color: $mine-shaft;

    &::placeholder {
      color: $mine-shaft;
      font-weight: 500;
      opacity: 0.4;
    }
  }

  label {
    font-family: $font-primary;
    min-height: 22px;
    margin-bottom: 5px;
    padding-bottom: 0;
    font-size: 0.875rem;
    color: $gray;
  }

  .eyeIcon {
    position: absolute;
    right: 15px;
    bottom: 10px;
  }
}
.inputLoginBorder {
  @extend .inputLogin;
}
.inputLoginDesc {
  @extend .inputLogin;
  .desc {
    position: absolute;
    right: 10px;
    top: 40px;
    background: $botticelli;
    font-family: $font-primary-bold;
    font-size: 1rem;
  }
}
.oneInput {
  @extend .inputLogin;
  margin-bottom: 0px;
}

.inputCleaner {
  @extend .inputLogin;
  width: 90%;
}

.inputForm {
  @extend .wrapper;
  height: 50px;
  margin-bottom: 40px;
  margin-top: 20px;
  position: relative;

  label {
    color: $mine-shaft !important;
    font-family: $font-primary-medium;
    text-transform: capitalize;
  }

  input {
    font-size: 1rem;
    font-family: $font-primary;
    background-color: $input-bg-secondary;
    font-weight: 500;

    &::placeholder {
      columns: $btn-primary-shadow;
      font-weight: 500;
      opacity: 0.4;
    }
  }

  .desc {
    position: absolute;
    right: 10px;
    top: 40px;
    background: $input-bg-secondary;
    font-family: $font-primary-bold;
    font-size: 1rem;
  }
  .copyToClipboard {
    position: absolute;
    top: 35px;
    right: 5px;
    cursor: pointer;
    svg {
      height: 25px;
      width: 25px;
    }
  }
  .input {
    border-radius: 3px;

    &:active {
      background-color: $input-bg-secondary !important;
    }
  }

  label {
    font-family: $font-primary;
    min-height: 22px;
    margin-bottom: 5px;
    padding-bottom: 0;
    font-size: 0.875rem;
    color: $silver-chalice;
  }

  @media only screen and (min-width: 600px) {
    margin-top: 0;
    margin-bottom: 50px;
    width: 100%;
  }
}

.inputFormWhite {
  @extend .inputForm;

  .desc {
    background-color: $white;
    top: 36px;
  }
  height: 42.5px !important;
  .input {
    height: 37.5px !important;
    margin-bottom: 5px;
    background-color: $white;
    text-align: center;
    &:active {
      background-color: $white !important;
    }
  }
}
.inputTable {
  @extend .wrapper;
  input {
    font-family: $font-primary;
  }
  display: flex;
  justify-content: center;
  .input {
    max-width: 150px;
    padding: 5px;
    text-align: center;

    &:active {
      outline: none;
    }
    &:disabled {
      cursor: auto;
      color: $black;
      background-color: unset !important;
      border: unset !important;
    }
  }
  .input,
  input {
    border: 1px solid $glacier;
    background-color: $white;
    border-radius: 3px;
  }
}
.inputFormWhiteBorderGray {
  @extend .inputFormWhite;
  .input {
    border: 1px solid #f4f3f3;
  }
}
.inputBorder {
  @extend .wrapper;

  .input {
    border-radius: 3px;
    border: 2px solid $btn-primary-border;
    padding: 8px;
    max-width: 168px;
    text-align: center;
    height: 35px;
  }
}

.inputBorderGray {
  @extend .inputBorder;

  .input {
    border: 2px solid $gray;
    text-align: left;
  }

  label {
    color: $gray;
  }
}

.inputProfileForm {
  @extend .inputForm;

  input {
    background-color: $botticelli;
    font-weight: 500;

    &::placeholder {
      columns: $btn-primary-shadow;
      font-weight: 500;
      opacity: 0.4;
      color: $mine-shaft !important;
    }
  }

  .input {
    border-radius: 3px;

    &:active {
      background-color: $botticelli !important;
    }
  }

  @media only screen and (min-width: 600px) {
    width: 100%;
  }
}

.phoneInput {
  margin-bottom: 50px;
  position: relative;
  height: 50px;
  border-radius: 3px;

  label {
    text-align: left;
    display: block;
    padding-left: 5px;
    font-family: $font-primary;
    min-height: 22px;
    margin-bottom: 5px;
    padding-bottom: 0;
    font-size: 0.875rem;
    color: $mine-shaft !important;
    text-transform: capitalize;
  }

  span {
    color: $terracotta !important;
  }

  input {
    max-height: 45px;
    padding: 15px;
    font-size: 1rem;
    font-family: $font-primary;
    background-color: $input-bg-secondary;
    font-weight: 500;
    border: none;
    width: 100%;

    &::placeholder {
      columns: $btn-primary-shadow;
      font-weight: 500;
      opacity: 0.4;
    }

    &:active {
      outline: none;
      background-color: $input-bg-secondary !important;
    }

    &:focus {
      outline: none;
    }
  }
}

.phoneInputLogin {
  margin-bottom: 15px;
  margin-top: 0;

  input {
    max-height: 45px;
    font-size: 0.8rem;
    font-family: $font-primary;
    background-color: $botticelli;
    font-weight: 500;
    color: $mine-shaft;
    padding: 15px;
    border: none;
    border-radius: 10px;
    width: 100%;

    &::placeholder {
      color: $mine-shaft;
      font-weight: 500;
      opacity: 0.4;
    }

    &:active {
      outline: none;
      background-color: $botticelli !important;
    }

    &:focus {
      outline: none;
    }
  }

  label {
    text-align: left;
    display: block;
    font-family: $font-primary;
    min-height: 22px;
    margin-bottom: 5px;
    padding-bottom: 0;
    font-size: 0.875rem;
    color: $gray;
    padding-left: 5px;
  }

  span {
    color: $terracotta !important;
  }
}

.phoneInputForm {
  @extend .phoneInputLogin;

  input {
    border-radius: 3px;
    font-size: 0.8em;
    font-weight: 600;

    &:disabled {
      cursor: not-allowed;
      color: $dove-gray;
    }
  }
}
.preDescInput {
  .input {
    padding-left: 25px;
  }
  .preDesc {
    position: absolute;
    left: 10px;
    bottom: -9px;
    font-family: $font-primary-bold;
    font-size: 1rem;
  }
}
.borderRed {
  .input,
  input {
    border: 2px solid $terracotta;
    border-radius: 3px;
  }
}
