@import "../../_styles/variables";

.wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  min-width: 750px;
  h3 {
    text-align: center;
  }
  .selectDate {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;

    .checkbox {
      label {
        margin-bottom: 0;
      }
    }
    .selectDisabled {
      cursor: not-allowed;
      opacity: 0.5;
    }
    .selectMonth {
      width: 115px;

      div {
        margin-bottom: 0;
      }
    }

    .selectYear {
      width: 80px;

      div {
        margin-bottom: 0;
      }
    }
  }

  .inputs {
    display: flex;
    flex-direction: row;
    gap: 10px;
  }
  div button {
    background-color: transparent;
    color: #183e4c;
    font-size: 0.875rem;
    border: 1px solid #183e4c;
    border-radius: 4px;
    padding: 9px 35px;
    margin-left: auto;
    &:hover {
      cursor: pointer;
    }
  }
  .btnDisabled {
    button {
      &:hover {
        cursor: not-allowed;
      }
      border: 1px solid #929a9c;
      color: #929a9c;
    }
  }
  table {
    width: 100%;
    border-collapse: collapse;

    thead {
      tr th:first-child {
        border-bottom: 1px solid transparentize($gray, 0.7);
      }
      th {
        color: $casal;
        padding: 20px 10px;
        position: sticky;
        top: 0;
        z-index: 1;
        min-width: 100px;
        background-color: $white;
        cursor: pointer;
        &:nth-child(1) {
          text-align: left;
          padding: 20px 20px;
          min-width: 200px;
        }

        &:nth-child(2n) {
          border-left: 1px solid transparentize($gray, 0.7);
          border-right: 1px solid transparentize($gray, 0.7);
        }
        button {
          display: inline;
          width: 20px;
          margin: 0;
        }
      }
    }

    tbody {
      tr td:first-child {
        color: $white;
        background-color: $glacier;
      }
      tr {
        &:nth-child(even) {
          background-color: $mercury;
          td {
            &:first-child {
              background-color: $dark-glacier;
            }
          }
        }

        &:nth-child(odd) {
          background-color: $wild-sand;
        }

        td {
          text-align: center;
          font-size: 1rem;
          padding: 10px 10px;
          white-space: nowrap;
          width: max-content;
          &:nth-of-type(even) {
            border-left: 1px solid transparentize($gray, 0.7);
            border-right: 1px solid transparentize($gray, 0.7);
          }

          &:nth-child(1) {
            text-align: left;
            padding: 10px 10px 10px 20px;
          }
          a {
            color: $white;
            &:hover {
              color: $mine-shaft;
            }
          }
        }
      }
      .firstTd {
        background-color: $casper;
      }
    }
  }
}
