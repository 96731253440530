@import "./../../_styles/_variables";

.wrapper {
  text-align: center;
  .tags {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    padding: 15px 0;
    max-height: 150px;
    overflow: auto;
    .tag {
      display: inline;
      cursor: pointer;
      width: max-content;
      border-radius: 10px;
      padding: 5px 10px;
      font-size: 20px;
      color: white;
      background-color: $light-casal;
      &:hover {
        background-color: $casal;
      }
    }
    .tagCustom {
      @extend .tag;
      background-color: $terracotta;
      &:hover {
        background-color: $terracotta-dark;
      }
    }
    .selected {
      background-color: $casal;
    }
    .selectedCustom {
      background-color: $terracotta-dark;
    }
  }
  hr {
    color: $botticelli;
    border: 1px solid $botticelli;
  }
  .addNew {
    .form {
      align-items: center;
      display: flex;
      flex-direction: column;
      .content {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-direction: column;
        align-items: center;
        @media only screen and (min-width: 700px) {
          flex-direction: row;
          gap: 20px;
        }
        .inputs {
          display: flex;
          flex-direction: column;
          gap: 20px;
          p {
            text-align: left;
            color: #242424;
          }
        }
      }

      .buttons {
        display: flex;
        justify-content: center;
        gap: 20px;
        margin-top: 20px;
        width: 90%;
        align-items: center;
        flex-direction: column;

        @media only screen and (min-width: 700px) {
          flex-direction: row;
        }
      }
    }
  }
}
