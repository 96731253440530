@import "../../../_styles/variables";

.wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: white;
  border-radius: 10px;
  padding: 20px;
  overflow: hidden;
  .contentContainer {
    overflow: auto;
    display: flex;
    flex-direction: column;
    width: 100%;
    .membersData {
      height: 100%;
    }
  }
}
