@import "./../../../_styles/variables";

.wrapper {
  display: flex;
  flex-direction: column;
  padding: 20px;

  @media only screen and (min-width: 500px) {
    padding: 0 20px;
  }

  .left {
    box-shadow: 0px 2px 6px #0000000a;
    border-radius: 4px;

    .header {
      display: flex;
      margin-bottom: 20px;
      flex-direction: column;

      @media only screen and (min-width: 500px) {
        flex-direction: row;
      }

      p {
        font-size: 1.125rem;
        cursor: pointer;
        font-family: $font-primary;
      }

      .tabActive {
        color: $color-text;
        font-weight: bold;
        display: flex;
        padding: 5px 10px;
        text-transform: capitalize;
        border-bottom: 2px solid $border-active;
      }

      .tabInactive {
        font-weight: bold;
        display: flex;
        padding: 5px 10px;
        text-transform: capitalize;
        color: $link-default;

        &:hover {
          color: $color-text;
          border-bottom: 2px solid $border-active;
        }
      }
    }

    .options {
      button {
        margin-bottom: 25px;
      }

      .buttons {
        display: flex;
        justify-content: space-between;
        margin-top: 15px;
        flex-direction: column-reverse;
        align-items: flex-start;
        gap: 10px;
        margin-top: 30px;

        @media only screen and (min-width: 992px) and (max-width: 1200px) {
          flex-direction: column-reverse;
          align-items: flex-start;
        }

        @media only screen and (min-width: 500px) and (max-width: 992px) {
          align-items: center;
          flex-direction: row;
        }

        @media only screen and (min-width: 1200px) {
          align-items: center;
          flex-direction: row;
        }

        .pickDate {
          width: 162px;
          display: flex;
          flex-direction: row;

          .dateError {
            color: $terracotta;
            margin-left: -40px;
          }
        }
      }
    }

    .containers {
      margin-top: 20px;
      display: flex;
      flex-direction: column;
      gap: 10px;
      max-height: 400px;
      overflow: auto;

      .row {
        display: flex;
        gap: 20px;
        align-items: flex-start;
        flex-direction: column;

        @media only screen and (min-width: 500px) {
          flex-direction: row;
          align-items: center;
        }

        .label {
          width: 180px;
          background-color: $botticelli;
          border-radius: 3px;
          padding: 8px;
          display: flex;
          justify-content: space-between;
          align-items: center;

          img {
            height: 20px;
            cursor: pointer;
          }
        }

        .inputGreen {
          input {
            border-color: rgb(112, 228, 112);
          }
        }

        button {
          margin-bottom: 0;
        }
      }
    }
  }

  .right,
  .fullScreen {
    box-shadow: 0px 2px 6px #0000000a;
    border-radius: 4px;
    overflow: auto;

    &::-webkit-scrollbar {
      width: 7px;
      height: 7px;
      padding: 5px;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 7px;
      --webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
      background: $casper;
    }
  }

  @media only screen and (min-width: 992px) {
    flex-direction: row;
    justify-content: space-between;
    gap: 1px;

    .left {
      width: 50%;
      background: $white;
      overflow-x: auto;
      padding: 20px;

      &::-webkit-scrollbar {
        width: 7px;
        height: 7px;
        padding: 5px;
      }

      &::-webkit-scrollbar-thumb {
        border-radius: 7px;
        --webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
        background: $casper;
      }

      .header {
        .tabActive {
          padding: 5px 10px;
          &:nth-child(2) {
            margin-left: 10px;
          }
        }

        .tabInactive {
          padding: 5px 10px;
          &:nth-child(2) {
            margin-left: 10px;
          }
        }
      }

      .containers {
        max-height: unset;
      }
    }

    .right {
      width: 50%;
      background: $white;
    }

    .fullScreen {
      width: 80%;
      margin: 0 auto;
      background: $white;
    }
  }
}

.error {
  p {
    color: $terracotta;
  }
}
