@import "../../_styles/variables";

.wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  h3 {
    text-align: center;
  }
  .filterOptions {
    padding: 10px;
    align-items: flex-end;
    gap: 10px;
    display: grid;
    grid-template-columns: repeat(1, 1fr);

    @media only screen and (min-width: 900px) {
      grid-template-columns: repeat(3, 1fr);
    }
    @media only screen and (min-width: 1400px) {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }
    .selector {
      width: 100%;
      div {
        margin-bottom: 0;
      }
    }
    .date {
      p {
        margin-bottom: 5px;
        font-size: 0.875rem;
        color: #929292;
      }
      div input {
        border: 1px solid #e6e6e6;

        height: 38px;
      }
    }
    .selectDate {
      @media only screen and (min-width: 700px) {
        margin-right: 0px;
      }

      display: flex;
      flex-direction: row;
      gap: 10px;
      .selectMonth {
        width: 115px;

        div {
          margin-bottom: 0;
        }
      }

      .selectYear {
        width: 80px;

        div {
          margin-bottom: 0;
        }
      }
    }
    button {
      width: 100px;
      margin-bottom: 6px;
    }
  }
  .report {
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    .graph {
      width: 100%;
      max-width: 1000px;
      text-align: center;
      padding: 0 50px;
      margin: auto;
      position: relative;

      .xLabel {
        font-size: 14px;
        margin: 0;
      }
      .yLabel {
        position: absolute;
        margin: 0;
        top: 40%;
        left: -20px;
        font-size: 14px;
        transform: rotate(-90deg);
        /* Safari */
        -webkit-transform: rotate(-90deg);
        /* Firefox */
        -moz-transform: rotate(-90deg);
        /* IE */
        -ms-transform: rotate(-90deg);
        /* Opera */
        -o-transform: rotate(-90deg);
      }
      .xUnderLabel {
        font-size: 16px;
        margin-top: 10px;
        font-weight: bold;
      }
    }
    p {
      font-size: 1.188rem;
      color: $link-default;
      font-family: $font-primary-bold;
    }
  }
}
