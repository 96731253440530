@import '../../_styles/variables';

.unclickable {
    pointer-events: none;
}

.wrapper,
.unclickable {
    ul {
        margin: auto;
        display: flex;
        flex-direction: row;
        width: max-content;
        justify-content: space-between;

        li {
            width: 40px;
            height: 40px;
            text-align: center;
            display: flex;
            align-items: center;
            text-align: center;
            justify-content: center;

            &:hover {
                cursor: pointer;
            }

            a {
                min-width: 35px;
                min-height: 35px;
                padding: 3px;
                color: $glacier;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 50%;

                &:hover {
                    background-color: $glacier;
                    color: $white;
                }
            }


        }
    }

    .active {
        a {
            background-color: $glacier;
            color: $white;
            font-size: 1.2rem;
        }
    }
}