@import "../../_styles/variables";

.btn {
  border: none;
  cursor: pointer;
  outline: none;
  transition: 0.5ms;
  display: flex;
  align-items: center;
  justify-content: center;
  color: $white;
  border-radius: 3px;
  font-size: 0.7rem;
  font-weight: 500;

  &:focus,
  &:active {
    outline: none;
  }

  &:hover {
    transition: 0.5ms;
  }

  &:disabled {
    cursor: not-allowed;
    outline: none;
    filter: brightness(50%);
  }

  .iconLeft {
    margin-right: 10px;
    display: flex;
    align-items: center;
    height: 10px;
    width: 10px;
  }

  .iconRight {
    margin-left: 10px;
    display: flex;
    align-items: center;
    height: 10px;
    width: 10px;
  }
}

.btnLogin {
  @extend .btn;
  width: 250px;
  height: 45px;
  background: $casal;
  font-size: 0.875rem;

  @media only screen and (min-width: 992px) {
    margin-top: 0;
    margin-bottom: 15px;
    width: 333px;
  }
}

.btnNormal {
  @extend .btn;
  width: 150px;
  height: 30px;
  background: $terracotta;

  @media only screen and (min-width: 600px) {
    margin-top: 0;
    margin-bottom: 15px;
    width: 150px;
  }
}

.btnNormalCasal {
  @extend .btnNormal;
  background: $casal;
}
.btnNormalCasalLight {
  @extend .btnNormal;
  background: $light-casal;
}

.btnStartScaning {
  @extend .btnNormalCasal;
  width: 100%;
  height: 100%;
  max-height: 109px;
  font-size: 25px;
  color: $white;

  .iconLeft {
    height: 35px;
    width: 35px;
  }
}

.btnNormalGlacier {
  @extend .btnNormal;
  background: $glacier;
}
.btnDarkGlacier {
  @extend .btnNormal;
  background: $dark-glacier;
}

.btnPremium {
  @extend .btnNormal;
  background: $botticelli;
  color: $mine-shaft;
  width: 110px;
  height: 42px;
  font-size: 1rem;
  font-family: $font-primary-bold;
}

.btnBack {
  @extend .btnNormal;
  background: $white;
  color: $mine-shaft;
  font-size: 1rem;
  font-weight: bold;
  width: 70px;
}

.btnClose {
  @extend .btnNormal;
  background: $wild-sand;
  color: $mine-shaft;
  font-size: 1rem;
  font-weight: bold;
  width: 35px;

  .iconLeft {
    margin-right: 0;
    height: 20px;
    width: 20px;
  }
}

.btnWhiteBackground {
  @extend .btn;
  width: 150px;
  height: 30px;
  background: $white;
  color: $color-text;
  border: 1px solid $dove-gray;

  @media only screen and (min-width: 600px) {
    margin-top: 0;
    margin-bottom: 15px;
    width: 150px;
  }
}
.btnBlackBackground {
  @extend .btn;
  width: 90px;
  height: 25px;
  color: $white;
  background-color: #020000;
  margin: 0;
  font-weight: 600;
}
.tabButtonActive {
  @extend .btn;
  border-radius: 8px;
  font-size: 1em;
  padding: 8px 15px;
  background-color: $casal;
  margin: 0;
  cursor: default;
  @media only screen and (min-width: 600px) {
    padding: 16px 25px;
    border-radius: 15px;
  }
  @media only screen and (min-width: 992px) {
    padding: 8px 12px;
    border-radius: 8px;
  }
  @media only screen and (min-width: 1300px) {
    padding: 16px 25px;
    border-radius: 15px;
  }
}
.tabButtonInactive {
  @extend .tabButtonActive;
  background-color: $wild-sand;
  color: $black;
  cursor: pointer;
}
