@import "../../../_styles/variables";

.wrapper {
  padding: 5px;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: auto;
  .restaurantInfo {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;

    img {
      width: 70px;
      height: 70px;
      border-radius: 50%;
      object-fit: cover;
    }
  }
  .options {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 15px;
    button {
      margin-bottom: 3px;
    }
  }
  .content {
    max-height: unset;
    height: 100%;
    margin-top: 30px;
  }
  @media only screen and (min-width: 550px) {
    padding: 30px 20px;
  }
}
