@import '../../_styles/variables';

.wrapper {
    padding: 0;
    display: flex;
    flex-direction: column-reverse;
    @media only screen and (min-width: 400px) {
        flex-direction: column;
    }
    @media only screen and (min-width: 1200px) {
        padding: 0 20px;
        height: 90%;
        flex-direction: row;
        justify-content: space-between;
    }
}