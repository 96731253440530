@import "../../../../_styles/variables";
.wrapper {
  label,
  p {
    color: $black !important;
  }
  h3 {
    text-align: center;
    margin-top: 0;
  }
  .error {
    color: $terracotta;
  }
  button {
    margin: auto;
  }
}
