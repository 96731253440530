$white: #ffffff;
$black: #000000;
$lightGray: #dfdfdf;
$lighterGray: #f1f1f1;
$black-russian: #090d28;
$glacier: #73aabd;
$dark-glacier: #548899;
$light-casal: #51aaca;
$system-blue: #59c0f4;
$system-blue-disabled: #358ab5;
$casal: #2b5b6c;
$casper: #a2c4d0;
$light-casper: #cceaf5;
$Bali-Hai: #8f9bb3;
$botticelli: #d5e5eb;
$botticelli-light: #e5f5fa;
$gray: #929292;
$silver: #b9b9b9;
$ghost: #c5c7cf;
$terracotta: #e2725b;
$terracotta-dark: #ca553e;
$mine-shaft: #242424;
$waterloo: #808495;
$dove-gray: #707070;
$mercury: #e4e4e4;
$wild-sand: #f4f3f3;
$silver-chalice: #b2b2b2;
$emperor: #4f4e4e;
$mischka: #d7dae2;
$scorpion: #636161;
$whiteSmoke: #f4f4f3;

$bg-primary: $casal;

$color-primary: $white;
$color-secondary: $botticelli;
$color-neutral: $gray;
$color-text: $mine-shaft;

$link: $gray;
$link-hover: $white;
$link-active: $glacier;
$link-default: $silver-chalice;

$btn-primary: $casal;
$btn-primary-shadow: $gray;
$btn-primary-active: $casal;
$btn-primary-color: $white;
$btn-secondary: $terracotta;
$btn-secondary-color: $white;
$btn-primary-border: $casper;

$input-bg: $black-russian;
$input-bg-secondary: $wild-sand;
$input-color: $white;
$input-placeholder: $mine-shaft;
$input-active-border: $waterloo;
$table-border: $mercury;

$header-gray: $scorpion;

$border-active: $terracotta;

$font-primary: "Cabin-regular";
$font-primary-bold: "Cabin-bold";
$font-secondary: "QuattrocentoSans-Regular";
$font-primary-medium: "Cabin-medium";
