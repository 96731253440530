@import "../../_styles/variables";

.wrapper {
  display: inline-block;
  width: max-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 15px;
  .imgContainer {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    overflow: hidden;
    border: none;
    margin-bottom: 10px;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .imgContainerSM {
    @extend .imgContainer;
    width: 40px;
    height: 40px;
  }
  .imgContainerSq {
    @extend .imgContainer;
    border-radius: 0%;
  }
  .inputContainer {
    display: inline-block;
    width: max-content;
    label {
      color: #165d72;
      text-decoration: underline;
      text-decoration-color: #165d72;
      cursor: pointer;
    }
  }
  @media only screen and (min-width: 1300px) {
    margin-right: 30px;
  }
}
