@import "../../../../_styles//variables";

.wrapper {
  padding: 20px 0;
  height: 100%;
  display: flex;
  flex-direction: column;

  .buttonsWrapper {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;

    button {
      margin-right: 10px;
      height: 30px;
      width: 142px;
    }
  }

  .avatarSection {
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;

    .img {
      width: 100px;
      height: 100px;
      border-radius: 50%;

      div {
        padding: 0;
        margin-top: 15px;
      }

      img {
        width: 100px;
        height: 100px;
        border-radius: 50%;
        object-fit: cover;
      }

      .imgHidden {
        @extend img;
        display: none;
      }
    }

    span {
      margin-top: 10px;
      font-size: 1.25rem;
      font-family: $font-primary-bold;
    }

    button {
      margin-top: 16px;
      margin-bottom: 5px;
      height: 30px;
      cursor: default;
    }
  }

  h1 {
    font-size: 1.188rem;
    font-family: $font-primary-bold;
  }

  .options {
    display: flex;
    flex-direction: column;
    align-items: center;

    button {
      margin-top: 20px;
    }
  }

  .noData {
    text-align: center;
    padding: 100px 0 100px 0;

    span {
      font-size: 1.188rem;
      color: $link-default;
      font-family: $font-primary-bold;
    }
  }

  @media only screen and (min-width: 475px) {
    .options {
      flex-direction: row;
      justify-content: space-between;

      button {
        &:nth-child(1) {
          margin-right: 5px;
        }

        &:nth-child(2) {
          margin-left: 5px;
        }
      }
    }
  }

  @media only screen and (min-width: 1024px) {
    .noData {
      padding-top: 200px;
    }

    .avatarSection {
      margin-top: 0;

      span {
        margin-top: 16px;
      }
    }
  }
}
