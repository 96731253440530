.wrapper {
  h2 {
    text-align: center;
    margin-top: 0;
  }
  form {
    display: flex;
    flex-direction: column;
    align-items: center;
    button {
      margin-bottom: 0;
    }
  }
}
