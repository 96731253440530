@import "../../../../_styles/variables";
.wrapper {
  .buttonsWrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 50px;
    button {
      margin-bottom: 0;
    }
  }
  .scanOptions {
    display: flex;
    flex-direction: row;
    background-color: $wild-sand;
    width: max-content;
    border-radius: 15px;
  }
  .camera {
    min-width: 250px;
    margin: auto;
    margin-top: 30px;
  }
  .scanningProgress {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    div {
      padding: 0;
    }
  }
}
