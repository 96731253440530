.wrapper {
  display: flex;
  flex-direction: column;
  h3,h4{
    text-align: center;
  }
  h4{
    margin-top: 0;
  }
  @media only screen and (min-width: 992px) {
      margin-bottom: 15px;
  } 
  button {
      margin-bottom: 0;
  }
  .row{
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
