@import "../../../../../_styles/variables";

.wrapper {
  max-height: 500px;
  background-color: $wild-sand;
  overflow-y: auto;
  margin-top: 20px;
  padding: 30px 0 30px 30px;
  border-radius: 3px;

  @media only screen and (min-width: 992px) {
    max-height: unset;
    height: 100%;
  }

  &::-webkit-scrollbar {
    width: 7px;
    height: 7px;
    padding: 5px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 7px;
    --webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
    background: $casper;
  }

  .listContainer {
    padding-bottom: 20px;
    border-bottom: 1px solid $mischka;

    .item {
      display: flex;
      padding-top: 10px;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: center;

      .name {
        display: flex;
        height: 40px;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        min-width: 40%;
        cursor: pointer;
      }

      .qrWrapper {
        padding: 5px 10px;
        display: flex;
        justify-content: right;
        align-items: center;
      }

      p {
        white-space: nowrap;
        margin: 0 5px 0 0;
        text-align: center;
      }

      img {
        width: 50px;
      }

      & > div {
        display: flex;
      }
    }
  }

  label {
    margin-bottom: 0;
    display: flex;
    align-items: center;
  }

  .smallerP {
    margin-top: 5px;
    font-size: 0.875rem;
    color: $gray;
  }

  .smallerPRed {
    margin-top: 5px;
    font-size: 0.875rem;
    color: $terracotta;
  }

  .loading {
    text-align: center;
    padding: 30px 0;
  }

  .noData {
    margin-top: 20px;
    text-align: center;
    font-size: 1.2rem;
  }
}
