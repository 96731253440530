@import '../../../_styles/variables';

.wrapper {
    background: $white;
    position: relative;
    padding: 20px 5px;
    border-radius: 4px;
    h2 {
        color: $color-text;
    }

    .close{
        position: absolute;
        right: 5px;
        top: 5px;
        cursor: pointer;

        img{
            width: 20px;
        }
    }

    .form{
        display: flex;
        flex-direction: column;
        padding: 0 30px;
        width: 100%;
        
        .center{
            display: flex;
            flex-direction: column;
            align-items: center;
            .selectRegion{
                margin-bottom: 50px;
                width: 100%;
            }
            & > div{
                width: 100%;
            }
            input{
                width: 100%;
            }
        }
        .submit{
            margin: auto;
        }
    }

    .error {
        p {
            color: $terracotta;
        }
    }
    @media only screen and (min-width: 992px) {
        .form{
            width: 50%;
            margin:auto;
        }
    }
    @media only screen and (min-width: 1300px) {
        
        padding: 20px 80px;
        box-shadow: 0px 2px 6px #0000000a;
        h2{
            padding: 0;
        }
        .close{
            top:15px;
            right:15px;
        }
    }
}