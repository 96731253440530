@import "./../../../../_styles/variables";

.wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;

  h2 {
    margin: 10px 0 0;
  }

  .header {
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-between;
    gap: 20px;

    .icon {
      span {
        font-size: 1rem;
        font-family: $font-primary-medium;
      }

      div {
        span:nth-of-type(1) {
          font-size: 3rem;
        }

        img {
          margin-left: 15px;
          height: 50px;
        }
      }
    }
    .buttons {
      display: flex;
      flex-direction: column;
      gap: 10px;

      @media only screen and (min-width: 550px) {
        flex-direction: row;
      }
      @media only screen and (min-width: 992px) and (max-width: 1300px) {
        flex-direction: column;
      }
      @media only screen and (min-width: 1300px) {
        flex-direction: row;
      }
    }
    @media only screen and (min-width: 500px) {
      flex-direction: row;
      padding-top: 20px;
    }

    @media only screen and (min-width: 992px) and (max-width: 1150px) {
      flex-direction: column-reverse;
    }
  }

  .searchAndFilter {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 16px;
    margin: 30px 0;
    align-items: center;

    @media only screen and (min-width: 992px) and (max-width: 1150px) {
      flex-direction: column;
      justify-content: flex-start;
    }

    @media only screen and (min-width: 1150px) {
      flex-direction: row;
    }

    .selector {
      width: 110px;
    }
    button {
      margin-bottom: 0;
    }
  }

  @media only screen and (min-width: 992px) {
    padding-top: 20px;

    h2,
    .header,
    .searchAndFilter {
      padding: 0 20px;
    }
  }

  .pagination {
    padding: 15px;
    margin-top: auto;
  }
}

.tableWrapper {
  overflow: scroll;
  min-height: 200px;

  &::-webkit-scrollbar {
    width: 7px;
    height: 7px;
    padding: 5px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 7px;
    --webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
    background: $casper;
  }
}
