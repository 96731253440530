@import "../../../_styles/variables";

.wrapper {
  display: flex;
  flex-direction: column;
  gap: 5px;

  @media only screen and (min-width: 500px) {
    padding: 0 20px;
  }

  .left,
  .left .list {
    &::-webkit-scrollbar {
      width: 7px;
      height: 7px;
      padding: 5px;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 7px;
      --webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
      background: $casper;
    }
  }

  .left {
    padding: 20px;
    box-shadow: 0px 2px 6px #0000000a;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    height: 100%;

    .header {
      display: flex;
      margin-bottom: 20px;

      p {
        font-size: 1.125rem;
        cursor: pointer;
        font-family: $font-primary;
      }

      .tabActive {
        color: $color-text;
        font-weight: bold;
        display: flex;
        padding: 5px 10px;
        text-transform: capitalize;
        border-bottom: 2px solid $border-active;
        margin-left: 10px;
      }

      .tabInactive {
        font-weight: bold;
        display: flex;
        padding: 5px 10px;
        text-transform: capitalize;
        color: $link-default;
        margin-left: 10px;

        &:hover {
          color: $color-text;
          border-bottom: 2px solid $border-active;
        }
      }
    }

    .options {
      display: flex;
      align-items: center;
      margin-bottom: 15px;
      flex-direction: column;
      gap: 15px;

      @media only screen and (min-width: 600px) {
        flex-direction: row;
      }
      .search {
        width: 100%;
        div {
          max-width: unset;
        }
      }
      .subOptions {
        display: flex;
        justify-content: space-between;
        .retireActive {
          &:hover {
            color: $terracotta;
          }
        }
        .select {
          width: 120px;
        }
      }

      & > div {
        display: flex;
        align-items: center;

        p {
          font-size: 0.85rem;
          margin: 0 15px;
          white-space: nowrap;
          color: $color-text;
        }
      }
    }

    .list {
      max-height: 416px;
      overflow-x: auto;
      margin-bottom: 15px;
      padding-top: 0;
      border-top: 1px solid transparentize($gray, 0.7);
      border-right: 1px solid transparentize($gray, 0.7);
      @media only screen and (min-width: 992px) {
        max-height: unset;
      }

      .tableHeader {
        position: sticky;
        top: 0;
        p {
          padding: 10px !important;
        }
      }

      .item,
      .tableHeader {
        display: grid;
        grid-template-columns: 1.3fr 1fr 0.8fr 1fr 40px;
        border-bottom: 1px solid transparentize($gray, 0.7);
        min-width: 600px;
        background-color: white;
        p {
          border-left: 1px solid transparentize($gray, 0.7);
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          margin: 0;
          line-height: 50px;
          padding: 0 10px;
          &:last-of-type {
            border-left: none;
          }
        }
      }
      .editIcon {
        &:hover {
          svg {
            height: 18px;
            width: 18px;
          }
        }
      }
      .item {
        cursor: pointer;
        label {
          position: unset;
          margin-bottom: 0;
          margin-left: auto;
        }
        &:hover {
          background-color: $botticelli;
        }
      }
      .selectedItem {
        @extend .item;
        background-color: $botticelli;
      }
      .tableHeader {
        margin: 0;
        z-index: 1;
        p {
          font-weight: 600;
          font-size: 1.1em;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          margin: 0;
        }
      }
    }

    .noBatchesMsg {
      margin-top: 30px;
      text-align: center;
    }
  }

  .right {
    box-shadow: 0px 2px 6px #0000000a;
    border-radius: 4px;
  }

  @media only screen and (min-width: 992px) {
    flex-direction: row;
    justify-content: space-between;

    .left {
      width: 60%;
      background: $white;
      overflow-x: auto;

      .header {
        .tabActive {
          margin-left: 25px;
        }

        .tabInactive {
          margin-left: 25px;
        }
      }

      .options {
        flex-direction: column;

        & > div {
          p {
            font-size: 1rem;
          }

          .select {
            width: 90px;
          }
        }

        .subOptions {
          max-width: 300px;
          display: flex;
          justify-content: space-between;
        }
      }
    }

    .right {
      width: 40%;
      background: $white;
    }
  }

  @media only screen and (min-width: 1240px) {
    .left {
      .options {
        flex-direction: row;

        & > div {
          .select {
            width: 150px;
          }
        }
      }

      .header {
        .tabActive {
          margin-left: 25px;
          padding: 5px 20px;
        }

        .tabInactive {
          margin-left: 25px;
          padding: 5px 20px;
        }
      }
    }
  }
  .buttons {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
    @media only screen and (min-width: 600px) {
      flex-direction: row;
      justify-content: flex-end;
    }
    button {
      margin-bottom: 0;
    }
  }
}
