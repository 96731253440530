@import "../../../../_styles/variables";

.wrapper {
  padding: 20px;
  background-color: $white;
  box-shadow: 0px 2px 6px #0000000a;
  border-radius: 4px;
  display: none;
  @media only screen and (min-width: 400px) {
    display: block;
  }
  .titleAndYear {
    h3 {
      margin: 10px 0;
    }
    .year {
      width: 120px;
      & > div > div {
        width: 120px;
      }
    }
  }

  .filter {
    margin-top: 20px;
  }

  @media only screen and (min-width: 1360px) {
    .titleAndYear {
      display: flex;
      justify-content: space-between;
      align-items: center;
      h3 {
        margin: 0;
      }
    }

    .filter {
      margin-top: 10px;
    }
  }
}
.noDataMsg {
  text-align: center;
  margin: 20px 0;
}
