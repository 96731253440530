@import "./../../../_styles/variables";

.wrapper {
  display: flex;
  flex-direction: column;
  padding: 20px;

  @media only screen and (min-width: 500px) {
    padding: 0 20px;
  }

  .left {
    padding: 20px;
    box-shadow: 0px 2px 6px #0000000a;
    border-radius: 4px;
    .header {
      display: flex;
      margin-bottom: 20px;
      flex-direction: column;

      @media only screen and (min-width: 500px) {
        flex-direction: row;
      }

      p {
        font-size: 1.125rem;
        cursor: pointer;
        font-family: $font-primary;
      }

      .tabActive {
        color: $color-text;
        font-weight: bold;
        display: flex;
        padding: 5px 10px;
        text-transform: capitalize;
        border-bottom: 2px solid $border-active;
      }

      .tabInactive {
        font-weight: bold;
        display: flex;
        padding: 5px 10px;
        text-transform: capitalize;
        color: $link-default;

        &:hover {
          color: $color-text;
          border-bottom: 2px solid $border-active;
        }
      }
    }
    .row {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: space-between;
      gap: 20px;
      margin-bottom: 20px;
      @media only screen and (min-width: 1300px) {
        flex-direction: row;
        align-items: center;
      }
      button {
        margin-bottom: 0;
      }
      .checkboxes {
        display: flex;
        flex-direction: column;
        label {
          margin-bottom: 10px;
        }
        @media only screen and (min-width: 500px) {
          flex-direction: row;
          align-items: center;
          label {
            margin-bottom: 0;
          }
        }
      }
    }
    .restaurants {
      div {
        p {
          margin: 10px 0;
        }
      }
    }
    .containers {
      margin-top: 30px;
      display: flex;
      flex-direction: column;
      gap: 15px;
      .item {
        display: flex;
        flex-direction: row;
        gap: 30px;
        align-items: center;
        .label {
          width: 180px;
          background-color: $botticelli;
          border-radius: 3px;
          padding: 8px;
          display: flex;
          justify-content: space-between;
          align-items: center;

          img {
            height: 20px;
            cursor: pointer;
          }
        }
      }
    }
  }

  .right {
    box-shadow: 0px 2px 6px #0000000a;
    border-radius: 4px;
    overflow: auto;

    &::-webkit-scrollbar {
      width: 7px;
      height: 7px;
      padding: 5px;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 7px;
      --webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
      background: $casper;
    }
  }

  @media only screen and (min-width: 992px) {
    flex-direction: row;
    justify-content: space-between;
    gap: 1px;
    .left {
      width: 50%;
      background: $white;
      overflow-x: auto;

      .header {
        .tabActive {
          padding: 5px 10px;
          &:nth-child(2) {
            margin-left: 10px;
          }
        }

        .tabInactive {
          padding: 5px 10px;
          &:nth-child(2) {
            margin-left: 10px;
          }
        }
      }
    }

    .right {
      width: 50%;
      padding: 20px;
      background: $white;
    }
    .fullScreen {
      width: 80%;
      margin: 0 auto;
      background: $white;
    }
  }
}
.error {
  p {
    color: $terracotta;
  }
}
