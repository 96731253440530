@import "../../../_styles/variables";

.wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: white;
  border-radius: 10px;
  padding: 20px;
  overflow: hidden;
  .header {
    color: #141414;
    font-size: 16px;
    margin-bottom: 15px;
    p {
      margin-top: 0;
    }
  }
  .contentContainer {
    overflow: auto;
    display: flex;
    flex-direction: column;
    gap: 15px;
    width: 100%;
  }
  .content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    background-color: #f7f7f7;
    border-radius: 8px;
    padding: 15px;
  }
  .row {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 80%;
    .name {
      display: flex;
      align-items: center;
      gap: 10px;
      p {
        font-size: 18px;
        font-weight: 600;
        margin: 0;
      }
      span {
        font-size: 14px;
        font-weight: 400;
        color: #141414;
      }
    }
    .emails {
      display: flex;
      flex-direction: row;
      gap: 10px;
      flex-wrap: wrap;
      .email {
        background-color: #abdced;
        border-radius: 5px;
        padding: 5px;
        span {
          margin-left: 10px;
          padding-right: 5px;
          cursor: pointer;
        }
      }
    }
    .addEmail {
      display: flex;
      flex-direction: row;
      gap: 10px;
      align-items: center;
      button {
        margin: 0;
        width: 68px;
        border-radius: 5px;
      }
    }
  }
  .limit {
    display: flex;
    flex-direction: column;
    gap: 5px;
    .limitDesc {
      display: flex;
      flex-direction: row;
      gap: 5px;
      p {
        margin: 0;
      }
      span {
        height: max-content;

        cursor: pointer;
        border-bottom: 1px solid #141414;
      }
    }
  }
}
.editor {
  display: flex;
  flex-direction: row;
  gap: 5px;
  align-items: center;
  button {
    margin: 0;
    width: 68px;
    border-radius: 5px;
  }
  div {
    width: 70px;
  }
}
