#root,
.App,
.main-wrapper {
  min-height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.page-content-public,
.page-content-private,
.page-content-sidebar {
  position: relative;
  display: block;
  height: 100%;
  width: 100%;
}

.page-content-private {
  padding-top: 50px;

  @media only screen and (min-width: 992px) {
    padding-top: 80px;
  }
}

.page-content-sidebar {
  align-items: start;
  height: 100%;

  section {
    height: 100%;
    overflow: auto;

    > div {
      height: 100%;
    }
  }

  .content,
  .content-op {
    width: calc(100% - 50px);
    margin-left: 50px;
    padding: 0;
    margin-top: 40px;
    padding-top: 20px;
    height: 100%;
    overflow: hidden;
  }

  @media only screen and (min-width: 992px) {
    section {
      height: 100%;

      > div {
        height: 100%;
        overflow: hidden;
      }
    }

    .content,
    .content-op {
      height: calc(100vh - 90px);
      width: calc(100% - 300px);
      margin-left: 300px;
      margin-top: 90px;
      padding: 20px;
      background: #e9e9e9;
    }
    .content-op {
      height: calc(100vh - 60px);
      margin-top: 60px;
    }
  }
}
content,
.content-op .container-wrapper {
  padding: 0 24px;
  margin: 0 auto;
  height: 100%;
  width: 100%;
}

.container-fluid-wrapper {
  width: 100%;
  padding: 0px;
  margin: 0 auto;
}

@media screen and (min-width: 768px) {
  .container-wrapper {
    padding: 0 30px;
  }
}

@media screen and (min-width: 1200px) {
  .container-wrapper {
    padding: 0px;
    max-width: 1160px;
  }
}
