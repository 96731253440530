.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  h2 {
    margin-top: 0;
    margin-right: auto;
  }
  .selector,
  .selectorRegion,
  .checkbox {
    width: 100%;
  }
  .checkbox {
    margin-top: 15px;
    label {
      margin-bottom: 0;
    }
  }
  .selectorRegion {
    margin-bottom: 30px;
  }
  button {
    margin-top: 20px;
  }
  .form {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
  }
}
