@import "../../../_styles/variables";

.wrapper {
  display: flex;
  flex-direction: column;
  gap: 24px;
  height: 100%;

  @media only screen and (min-width: 992px) {
    padding: 30px 20px;
  }

  .header {
    display: flex;
    justify-content: flex-end;
  }

  .couponInfos {
    text-align: center;

    .inputGroup {
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      @media only screen and (min-width: 1200px) {
        flex-direction: row;
        gap: 16px;
      }
    }
  }
  .showDetails {
    margin-top: auto;
    margin-left: auto;
    button {
      margin-bottom: 0;
    }
  }
}
.detailsWrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
  @media only screen and (min-width: 992px) {
    padding: 30px 20px;
  }
  button {
    margin-bottom: 0;
  }
  .table {
    overflow: auto;
    .row {
      display: flex;
      flex-direction: row;
      padding-bottom: 5px;
      border-bottom: 2px solid $botticelli;
      margin: 16px 0;
      min-width: 310px;
      .ind {
        font-weight: bold;
      }
      p {
        padding-left: 10px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin: 0;
        width: 100%;
        min-width: 330px;
        span {
          display: inline-block;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          max-width: 200px;
        }
        a {
          color: $black;
          &:hover {
            color: $casal;
          }
        }
      }
    }
  }
}
.noData {
  text-align: center;
  padding: 50px 0;

  span {
    font-size: 1.188rem;
    color: $link-default;
    font-family: $font-primary-bold;
  }

  @media only screen and (min-width: 992px) {
    padding: 200px 0;
  }
}
