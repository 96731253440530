@import "../../_styles/variables";

.wrapper {
  height: 100%;

  padding: 10px;
  overflow: hidden;
  @media only screen and (min-width: 500px) {
    padding: 0 20px;
  }
  .header {
    text-align: center;
    @media only screen and (min-width: 992px) {
      padding: 0 30px;
    }
  }

  .contentWrapper {
    width: 100%;
    background-color: $white;
    height: 100%;
    box-shadow: 0px 2px 6px #0000000a;
    border-radius: 4px;
    display: flex;
    flex-direction: column;

    .content {
      @media only screen and (min-width: 992px) {
        padding: 0 30px 20px;
      }

      height: 100%;
      overflow: auto;
      padding: 10px;
      display: flex;
      flex-direction: column;

      @media only screen and (min-width: 1350px) {
        flex-direction: row;
        .sideMenu {
          border-right: 1px solid $glacier;
        }
      }

      .sideMenu {
        width: 200px;
        padding-right: 10px;
        display: flex;
        flex-direction: column;
        gap: 10px;
        margin-bottom: 20px;
        .listItem {
          background-color: $glacier;
          color: $white;
          border-radius: 5px;
          padding: 10px;
          font-size: 1rem;
          cursor: pointer;
        }
      }
      .settings {
        width: 100%;
      }
    }
  }
}
