@import "../../../../../_styles/variables";

.table {
  max-height: 656px;
  overflow: auto;
  border-radius: 4px;
  @media only screen and (min-width: 992px) {
    height: max-content;
  }
  &::-webkit-scrollbar {
    width: 7px;
    height: 7px;
    padding: 5px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 7px;
    --webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
    background: $silver;
  }

  table {
    width: 100%;
    border-collapse: collapse;

    thead {
      .topBar {
        background-color: $glacier;

        th {
          padding: 12px 20px;

          &:nth-child(1) {
            background-color: $glacier;
            color: $white;
            font-size: 1.188rem;
            margin: 0;
          }

          button {
            background-color: transparent;
            color: $white;
            font-size: 0.875rem;
            border: 1px solid $white;
            border-radius: 4px;
            padding: 9px 35px;
            margin-left: auto;
            &:hover {
              cursor: pointer;
            }
          }
        }
        .btnDisabled {
          button {
            &:hover {
              cursor: not-allowed;
            }
            border: 1px solid #183e4c;
            color: #183e4c;
          }
        }
      }

      tr {
        th {
          color: $casal;
          padding: 20px 10px;

          &:nth-child(1) {
            text-align: left;
            padding: 20px 20px;
          }
        }
      }
    }

    tbody {
      tr {
        &:nth-child(even) {
          background-color: $mercury;
        }

        &:nth-child(odd) {
          background-color: $wild-sand;
        }

        &.edit {
          td {
            input {
              background-color: $white;
              border-color: $glacier;
            }
          }
        }

        td {
          text-align: center;
          font-size: 1rem;
          padding: 10px 10px;

          input {
            background-color: transparent;
            width: 70px;
            font-size: 1rem;
            font-family: $font-primary;
            border-color: transparent;
            padding: 0px;

            &:disabled {
              color: $black;
            }
          }
          label {
            margin-bottom: 0;
            justify-content: center;
            input {
              width: 0;
            }
          }
          &:nth-child(1) {
            text-align: left;
            padding: 10px 10px 10px 20px;
          }
        }
      }
    }
  }
}
.loading {
  text-align: center;
  padding: 20px 0;
}
