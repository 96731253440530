@import "../../../_styles/variables";

.wrapper {
  display: flex;
  flex-direction: column;
  gap: 24px;
  height: 100%;

  @media only screen and (min-width: 992px) {
    padding: 30px 20px;
  }

  .header {
    display: flex;
    gap: 8px;
    justify-content: space-between;
    align-items: center;
  }

  .restaurantInfos {
    text-align: center;
    img {
      width: 100px;
      height: 100px;
      border-radius: 50%;
      object-fit: cover;
    }
    form {
      .inputGroup {
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        @media only screen and (min-width: 1300px) {
          flex-direction: row;
          gap: 16px;
        }
      }
    }
    .draft {
      color: $terracotta;
    }
  }

  .footer {
    display: flex;
    gap: 16px;
    justify-content: flex-end;
    align-items: center;
    flex-direction: column;
    gap: 16px;
    @media only screen and (min-width: 500px) {
      flex-direction: row;
      margin-bottom: 20px;
    }
    @media only screen and (min-width: 992px) {
      flex-direction: column;
    }
    @media only screen and (min-width: 1400px) {
      flex-direction: row;
    }
  }
}

.noData {
  text-align: center;
  padding: 50px 0;

  span {
    font-size: 1.188rem;
    color: $link-default;
    font-family: $font-primary-bold;
  }

  @media only screen and (min-width: 992px) {
    padding: 200px 0;
  }
}
