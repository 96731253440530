@import "../../_styles/variables";
.wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
  h3 {
    margin-top: 15px;
    text-align: center;
  }
  .content {
    padding: 20px;
    overflow: scroll;
    height: 100%;
  }
  .addSection {
    margin: auto;
    width: 150px;
    cursor: pointer;
    border-radius: 10px;
    background-color: $light-casal;
    color: $white;
    padding: 0 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    align-self: center;
    gap: 10px;
    span {
      font-size: 1.5em;
    }
  }
}
