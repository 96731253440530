@import "../../_styles/variables";

.checkbox {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  position: relative;
  input {
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  .spanLabel {
    margin-left: 15px;
    color: $silver;
    font-family: $font-primary;
    font-weight: 600;
    font-size: 0.875rem;
    cursor: pointer;

    @media only screen and (min-width: 992px) {
      margin-left: 10px;
    }
  }

  .checkmark {
    display: flex;
    height: 20px;
    width: 20px;
    border-radius: 4px;
    border: 1px solid $waterloo;
    background-color: $white;
    box-shadow: 0px 1px 3px rgba(53, 55, 61, 0.1);
    align-items: center;
    justify-content: center;
    cursor: pointer;

    &:after {
      content: "";
      position: relative;
      display: none;
    }
  }

  input:checked ~ .checkmark {
    border-radius: 4px;
    background-color: $white;
  }

  input:checked ~ .checkmark:after {
    display: block;
  }

  .checkmark:after {
    animation: fadeIn 0.2s linear forwards;
    width: 4px;
    height: 12px;
    border: solid $black;
    border-radius: 0px;
    background-color: $white;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
  .hidden {
    display: none;
    position: absolute;
    top: -25px;
    color: $white;
    background-color: black;
    border-radius: 3px;
    padding: 5px;
    width: max-content;
    z-index: 100;
  }
  &:hover {
    .hidden {
      display: block;
    }
  }
}
.checkboxFix {
  @extend .checkbox;
  margin-bottom: 0;
}

.desc {
  display: flex;
  color: $white;
  margin-bottom: 10px;
}

.secondaryCheckbox {
  @extend .checkbox;

  @media only screen and (min-width: 1300px) {
    margin-bottom: 0;
    margin-left: 10px;

    .spanLabel {
      margin-left: 15px;
      color: $silver;
      font-family: $font-primary;
      font-weight: 600;
      font-size: 0.875rem;

      @media only screen and (min-width: 992px) {
        margin-left: 10px;
        margin-right: 0;
        margin-top: 0;
      }
    }
  }
}

.largeCheckbox {
  @extend .checkbox;

  .checkmark {
    width: 35px;
    height: 35px;
  }

  .checkmark:after {
    width: 8px;
    height: 22px;
    border-width: 0 2.5px 2.5px 0;
    z-index: 0;
  }
}
