@import "../../../../_styles/variables";

.wrapper {
  .noData {
    text-align: center;
  }

  &::-webkit-scrollbar {
    width: 7px;
    height: 7px;
    padding: 5px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 7px;
    --webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
    background: $casper;
  }

  overflow: auto;
  height: 100%;
  max-height: 400px;

  @media only screen and (min-width: 992px) {
    max-height: unset;
  }

  table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0;

    .userData {
      min-width: 150px;
    }

    thead th {
      position: sticky;
      top: 0;
      color: $gray;
      font-size: 0.875rem;
      font-weight: normal;
      border-top: 1px solid transparentize($gray, 0.7);
      border-bottom: 1px solid transparentize($gray, 0.7);
      padding: 5px 15px;
      white-space: nowrap;
      cursor: pointer;
      background-color: $white;

      &:first-of-type {
        text-align: center;
        z-index: 1;
      }

      &:nth-child(2n) {
        border: 1px solid transparentize($gray, 0.7);
      }

      button {
        display: inline;
        width: 20px;
        margin: 0;
      }
      p {
        width: 56px;
      }
      &:hover {
        p {
          display: none;
        }
      }
    }

    tbody {
      tr {
        td {
          text-align: center;
          color: $mine-shaft;
          border-bottom: 1px solid transparentize($gray, 0.7);
          padding: 5px 10px;

          &:first-of-type {
            text-align: center;
            padding: 5px 20px;
          }

          &:nth-of-type(even) {
            border-left: 1px solid transparentize($gray, 0.7);
            border-right: 1px solid transparentize($gray, 0.7);
          }

          label {
            width: max-content;
            margin: auto;
          }
          img {
            width: 30px;
            height: 30px;
            border-radius: 50%;
          }
          .card {
            display: flex;
            justify-content: center;
            svg {
              width: 30px;
              height: 30px;
            }
          }
          .contact {
            display: flex;
            flex-direction: column;
            align-items: center;
          }
        }

        &.selected {
          background-color: $botticelli;
        }

        &:hover {
          background-color: $botticelli;
          cursor: pointer;
        }
      }

      .active {
        background-color: $botticelli;
      }
    }
  }

  .loading {
    text-align: center;
    padding: 30px 0;
  }

  @media only screen and (min-width: 500px) {
    table {
      thead th {
        padding: 12px 20px;
      }

      tbody {
        tr {
          td {
            p {
              margin: 10px 0;
            }
          }
        }
      }
    }
  }
}
