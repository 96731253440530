@import "../../../_styles/variables";

.wrapper {
    width: 100%;

    .container {
        position: absolute;
        z-index: 9999999;
        background: #fbfbfb 0% 0% no-repeat padding-box;
        border-radius: 5px;
        margin: 10px 0;
        margin-top: -25px;
        
        .content {
            background-color: $input-bg-secondary;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 10px;
            cursor: pointer;

            .type {
                text-transform: capitalize;
            }

            &:hover {
                background-color: $lightGray;
            }
        }

        .options {
            display: flex;
            padding: 10px;
            cursor: pointer;

            p {
                margin-left: 20px;
                text-decoration: underline;
            }
        }
    }
}
