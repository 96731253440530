@import "./../../../../_styles/variables";

.select {
    display: flex;
    align-items: center;
    cursor: pointer;

    img {
        height: 20px;
    }

    p {
        margin: 0 0 0 15px;
    }
}
.selectDisabled{
    @extend .select;
    cursor: not-allowed;
}
.dropdown {
    position: absolute;
    box-shadow: 0px 3px 6px $casal;
    border: 1px solid $casper;
    border-radius: 3px;
    width: 170px;
    background-color: $white;
    display: none;
    max-height: 300px;
    overflow: auto;

    .dropdownItem {
        cursor: pointer;
        margin: 0;
        padding: 8px 10px;

        &:hover {
            background-color: $botticelli;
        }
    }

    &::-webkit-scrollbar {
        width: 7px;
        height: 7px;
        padding: 5px;
    }

    &::-webkit-scrollbar-thumb {
        border-radius: 7px;
        --webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
        background: $casper;
    }
}

.showDropdown {
    position: relative;

    &:hover .dropdown {
        display: block;
    }
}

.hideDropdown {
    .dropdown {
        display: none;
    }
}

.error {
    color: $terracotta;
    margin-left: 5px;
}