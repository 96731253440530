@import "../../../../../_styles/variables";

.wrapper {
  margin-top: 36px;

  .close {
    position: absolute;
    top: 15px;
    right: 15px;

    &:hover {
      cursor: pointer;
    }
  }

  .content {
    position: relative;
    border-radius: 4px;
    background-color: $wild-sand;
    padding: 25px;
    margin-bottom: 20px;
  }

  .table {
    border-radius: 4px;
    width: 100%;
    overflow-x: auto;

    h1 {
      padding-left: 0;
      border-bottom: 1px solid #a2c4d0;
      padding-bottom: 20px;
      margin-top: 0;
    }

    &::-webkit-scrollbar {
      width: 7px;
      height: 7px;
      padding: 5px;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 7px;
      --webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
      background: $casper;
    }

    table {
      margin-top: 30px;
      width: 100%;
      border-spacing: 0;
    }

    table caption {
      text-align: left;
      padding-bottom: 20px;
      font-size: 1rem;
      font-family: $font-primary-bold;
    }

    table thead {
      background-color: $casper;
      color: $white;
    }

    table thead tr th {
      padding: 10px;
    }

    table tbody tr td {
      font-size: 0.875rem;
      font-weight: 500;
      padding: 10px;
      text-align: center;
    }

    table thead tr th:first-child {
      text-align: left;
    }

    table tbody tr td:first-child {
      text-align: left;
    }

    table thead tr th:last-child {
      text-align: right;
    }

    table tbody tr td:last-child {
      text-align: right;
    }
  }

  .priceRow {
    display: flex;
    flex-direction: column;
    padding: 10px;
    align-content: end;
    margin-top: 20px;

    div {
      width: max-content;
      margin-left: auto;

      .totalPrice {
        font-size: 1rem;
        display: flex;
        text-align: right;
        padding-bottom: 4px;
        justify-content: space-between;

        &:last-child {
          padding-top: 5px;
          border-top: 1px solid $casal;
        }

        span {
          display: inline-block;
          width: 70px;
          text-align: left;
        }
      }
    }
  }

  .date {
    margin-top: 50px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;

    .createdDate p {
      margin: 0;
    }

    & > div {
      display: flex;
      flex-direction: row-reverse;

      .signatureLine {
        height: 20px;
        width: 150px;
        border-bottom: 1px solid $casper;
      }
    }
  }

  @media only screen and (min-width: 1281px) {
    .date {
      margin-top: 80px;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      p {
        margin: 0;
      }

      & > div {
        display: flex;
        flex-direction: column;
        align-items: center;

        .signatureLine {
          height: 35px;
        }
      }
    }
  }

  .buttons {
    display: flex;
    justify-content: flex-end;
    gap: 20px;

    button {
      margin-bottom: 0;
    }
  }
  .downloadButton {
    text-decoration: none;
    border: none;
    cursor: pointer;
    outline: none;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 3px;
    font-size: 0.8rem;
    font-weight: 500;

    &:focus,
    &:active {
      outline: none;
    }

    width: 150px;
    height: 30px;
    background: $white;
    color: $color-text;
    border: 1px solid $dove-gray;
  }
}
