@import "../../../../_styles/variables";
.wrapper {
  width: 100%;

  overflow: hidden;
  .topBar {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    background-color: $glacier;
    border-top-right-radius: 6px;
    border-top-left-radius: 6px;
    gap: 20px;
    @media only screen and (min-width: 700px) {
      flex-direction: row;
    }
    h3 {
      background-color: $glacier;
      color: $white;
      font-size: 1.188rem;
      margin: 0;
    }
    .tabActive {
      color: $white;
      font-weight: bold;
      display: flex;
      padding: 5px 10px;
      text-transform: capitalize;
      cursor: pointer;
    }

    .tabInactive {
      font-weight: bold;
      display: flex;
      text-transform: capitalize;
      color: $mischka;
      padding: 5px 10px;

      &:hover {
        color: $white;
        cursor: pointer;
      }
    }
    padding: 12px 20px;
    div button {
      background-color: transparent;
      color: $white;
      font-size: 0.875rem;
      border: 1px solid $white;
      border-radius: 4px;
      padding: 9px 35px;
      margin-left: auto;
      &:hover {
        cursor: pointer;
      }
    }

    .datepicker {
      margin: auto;
      @media only screen and (min-width: 700px) {
        margin-left: auto;
        margin-right: 0px;
      }
      span {
        color: $white;
      }
      &:hover {
        cursor: pointer;
      }
    }
    .datepickers {
      display: flex;
      flex-direction: column;
      @media only screen and (min-width: 550px) {
        flex-direction: row;
      }
      @media only screen and (min-width: 700px) {
        flex-direction: column;
      }
      @media only screen and (min-width: 992px) {
        flex-direction: row;
      }
      .datepicker {
        margin: 0;
      }
    }
    .selectedDate {
      margin: auto;
      @media only screen and (min-width: 700px) {
        margin-left: auto;
        margin-right: 0px;
      }
      display: flex;
      flex-direction: row;
      gap: 10px;
      .selectMonth {
        width: 115px;

        div {
          margin-bottom: 0;
        }
      }

      .selectYear {
        width: 80px;

        div {
          margin-bottom: 0;
        }
      }
    }
    .btnDisabled {
      button {
        &:hover {
          cursor: not-allowed;
        }
        border: 1px solid #183e4c;
        color: #183e4c;
      }
    }
  }
  .inventory {
    max-height: 80vh;
    overflow: auto;
    width: 100%;
    table {
      width: 100%;
      border-collapse: collapse;

      thead {
        tr th:first-child {
          position: sticky;
          left: 0;
          z-index: 2;
          border-bottom: 1px solid transparentize($gray, 0.7);
        }
        th {
          color: $casal;
          padding: 20px 10px;
          position: sticky;
          top: 0;
          z-index: 1;
          min-width: 100px;
          white-space: nowrap;
          width: max-content;
          background-color: $white;
          cursor: pointer;
          &:nth-child(1) {
            text-align: left;
            padding: 20px 20px;
            min-width: 200px;
          }

          &:nth-child(2n) {
            border-left: 1px solid transparentize($gray, 0.7);
            border-right: 1px solid transparentize($gray, 0.7);
          }
          button {
            display: inline;
            width: 20px;
            margin: 0;
          }
        }
      }

      tbody {
        tr td:first-child {
          position: sticky;
          left: 0;
          color: $white;
          background-color: $glacier;
        }
        tr {
          &:nth-child(even) {
            background-color: $mercury;
            td {
              &:first-child {
                background-color: $dark-glacier;
              }
            }
          }

          &:nth-child(odd) {
            background-color: $wild-sand;
          }

          td {
            text-align: center;
            font-size: 1rem;
            padding: 10px 10px;
            white-space: nowrap;
            width: max-content;
            &:nth-of-type(even) {
              border-left: 1px solid transparentize($gray, 0.7);
              border-right: 1px solid transparentize($gray, 0.7);
            }

            &:nth-child(1) {
              text-align: left;
              padding: 10px 10px 10px 20px;
            }
            a {
              color: $white;
              &:hover {
                color: $mine-shaft;
              }
            }
          }
        }
        .firstTd {
          background-color: $casper;
        }
      }
    }
  }
  .graphWrapper {
    p {
      text-align: center;
    }
  }
  .graphs {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    gap: 20px;
    @media only screen and (min-width: 1400px) {
      flex-direction: row;
    }
  }
  .graph {
    max-width: 800px;
    padding: 0 50px;
    position: relative;

    .xLabel {
      font-size: 14px;
      margin: 0;
    }
    .yLabel {
      position: absolute;
      margin: 0;
      top: 40%;
      left: -20px;
      font-size: 14px;
      transform: rotate(-90deg);
      /* Safari */
      -webkit-transform: rotate(-90deg);
      /* Firefox */
      -moz-transform: rotate(-90deg);
      /* IE */
      -ms-transform: rotate(-90deg);
      /* Opera */
      -o-transform: rotate(-90deg);
    }
    .xUnderLabel {
      font-size: 16px;
      margin-top: 10px;
      font-weight: bold;
    }
  }
}
