* {
  box-sizing: border-box;
}

body,
html {
  font-family: $font-primary;
  height: 100vh;
  margin: 0;
  color: $black;
  box-sizing: border-box;
  overflow-x: hidden;
  font-size: 16px;
}

a {
  color: $link;
  text-decoration: none;
  cursor: pointer;

  &:hover,
  :active {
    color: $link-hover;
    text-decoration: none;
  }
}

ul {
  list-style-type: none;
  padding-inline-start: 0;
  margin: 0;
}

/* Extra Small Devices, Phones */
@media only screen and (min-width: 480px) {
}

/* Small Devices, Tablets */
@media only screen and (min-width: 768px) {
}
/* Medium Devices, Desktops */
@media only screen and (min-width: 992px) {
}

/* Large Devices, Wide Screens */
@media only screen and (min-width: 1200px) {
}

@media print {
  html,
  body {
    height: initial !important;
    overflow: initial !important;
    -webkit-print-color-adjust: exact;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

@media print {
  .page-break {
    margin-top: 1rem;
    display: block;
    page-break-before: auto;
    break-inside: avoid;
  }
}

div,
table {
  &::-webkit-scrollbar {
    width: 5px;
    height: 5px;
    padding: 5px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 7px;
    --webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
    background: $casper;
  }
}
