@import "../../../_styles/variables";

.wrapper {
  background-color: $white;
  border-radius: 4px;
  padding: 10px;
  text-align: center;
  display: flex;
  align-items: center;
  flex-direction: column;
  @media only screen and (min-width: 600px) {
    padding: 20px 30px;
  }
  h2 {
    padding-bottom: 10px;
    width: 100%;
    border-bottom: 1px solid $casper;
  }

  .notificationBody {
    padding: 10px;
    width: 100%;
    .input {
      position: relative;
      .smileIcon {
        position: absolute;
        right: 5px;
        top: 5px;
        &:hover {
          cursor: pointer;
        }
      }
    }
    .emojiPicker {
      aside {
        position: relative;
        nav {
          width: 100% !important;
          position: relative !important;
        }
      }
    }
  }

  .error {
    p {
      margin: 0;
      color: $terracotta;
    }
  }
}
