@import "./../../../../_styles/variables";
.wrapper {
  width: 100%;
  .title {
    font-size: 1.2rem;
    margin-bottom: 20px;
    display: flex;
    flex-direction: row;
    border: 1px solid $input-bg-secondary;
    div {
      margin-bottom: 0;
      height: unset !important;
      input {
        text-align: left;
        margin-bottom: 0;
      }
      margin-right: 10px;
    }
    .showDetails {
      padding: 0 10px;
      margin: auto;
      cursor: pointer;
      font-size: 1.2em;
    }
    .titleText {
      width: 100%;
      font-size: 0.8em;
      padding: 0 10px;
      text-align: left 5px;
    }
  }
  svg {
    cursor: pointer;
    margin: auto;
    height: 30px;
    width: 30px;
    padding-right: 10px;
  }
  .questions {
    padding-left: 20px;
    .qa {
      display: flex;
      flex-direction: row;
      gap: 20px;
      border-bottom: 1px solid $glacier;
      margin-bottom: 20px;
      svg {
        margin-top: 0;
      }
    }
  }

  .answer {
    textarea {
      height: 100px;
    }
  }

  .addQA {
    margin: auto;
    width: 120px;
    cursor: pointer;
    border-radius: 10px;
    background-color: $glacier;
    color: $white;
    padding: 0 10px 0 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    align-self: center;
    gap: 10px;
    margin-bottom: 30px;
    span {
      font-size: 1.5em;
    }
  }
  .error {
    text-align: center;
    color: $terracotta;
  }

  .buttons {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 20px;
  }
}
