@import "../../../_styles/variables";

.wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;

  .header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    h2 {
      margin-top: 0;
    }

    padding: 0;

    @media only screen and (min-width: 992px) {
      padding: 0 20px;
    }
    .buttons {
      display: flex;
      flex-direction: column;

      @media only screen and (min-width: 1500px) {
        flex-direction: row;
        gap: 20px;
      }
    }
  }

  @media only screen and (min-width: 992px) {
    padding-top: 30px;
  }
  .chartWrapper {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    @media only screen and (min-width: 992px) {
      padding: 10px 20px;
    }
  }
}
