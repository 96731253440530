@import "../../_styles/variables";
.wrapper {
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 20px;
  .progressBar {
    border-radius: 2px;
    position: relative;
    height: 30px;
    min-width: 200px;
    width: 100%;
    background-image: linear-gradient(
      135deg,
      rgba(217, 218, 253, 0.4) 40%,
      rgba(207, 207, 207, 0.8) 40%,
      rgba(207, 207, 207, 0.8) 50%,
      rgba(217, 218, 253, 0.4) 50%,
      rgba(217, 218, 253, 0.4) 90%,
      rgba(207, 207, 207, 0.8) 90%,
      rgba(207, 207, 207, 0.8) 100%
    );
    background-size: 15px 15px;

    .progressDone {
      background: $casper;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      width: 0;
      opacity: 0;
      transition: 1s ease 0.3s;
      border-radius: 2px;
    }
  }
  .percentage {
    position: absolute;
    top: 50%;
    left: 50%;

    transform: translate(-50%, -50%);
  }
}
