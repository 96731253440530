@import "../../../../_styles/variables";

.wrapper {
  display: flex;
  flex-direction: column;
  overflow: auto;
  height: 100%;

  .buttonsWrapper {
    display: flex;
    justify-content: space-between;

    button {
      margin-top: 20px;
      margin-right: 10px;
    }
  }

  .paymentTitle {
    padding: 0;
    margin-top: 20px;
    text-align: center;
  }

  .selectYear {
    margin-top: 20px;
    display: flex;
    flex-direction: row-reverse;
  }

  .list {
    overflow-x: auto;
    height: 100%;
    background-color: $wild-sand;
    margin-top: 30px;

    &::-webkit-scrollbar {
      width: 7px;
      height: 7px;
      padding: 5px;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 7px;
      --webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
      background: $casper;
    }

    .block {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr;
      justify-content: flex-start;
      align-items: center;
      padding-left: 15px;
      height: 60px;
      color: $mine-shaft;
      font-size: 12px;
      background-color: $wild-sand;
      border-bottom: 1px solid $mercury;

      .transactionTypeBlock {
        width: 130px;
      }

      .transactionStatus,
      .transactionDateBlock {
        width: 80px;
      }

      .blockButton {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding-right: 20px;
        padding-left: 20px;
        width: 126px;

        button {
          width: 86px;
          height: 32px;
          margin-bottom: 0;
        }
      }
    }

    .noTransactionsMsg {
      text-align: center;
      margin-top: 30px;
    }
  }

  @media only screen and (min-width: 1281px) {
    .list {
      .block {
        font-size: 1rem;
      }
    }
  }
}
