@import "../../../_styles/variables";

.input-wrapper {
  position: relative;

  .input-field {
    width: 100%;
    border: none;
    border-bottom: 1px solid #b9b9b9;
    background-color: transparent;
    outline: none;
    font-size: 1rem;
    transition: border-bottom-color 0.3s ease;

    &:focus {
      border-bottom-color: $casal;
    }

    &::placeholder {
      color: #848484;
      text-align: center;
    }
  }

  .errorMsg {
    color: $terracotta;
    font-size: 0.875rem;
    margin-top: 0.25rem;
  }
}
.borderRed {
  .input-field,
  input {
    border-bottom-color: $terracotta;
    transition: border-bottom-color 0s ease;
  }
}
