@import "../../../../_styles/variables";

.wrapper {
  background-color: $white;
  padding: 30px;
  border-radius: 4px;
  form {
    display: flex;
    flex-direction: column;
    .content {
      display: flex;
      flex-direction: column;

      .inputFields {
        textarea {
          height: 60px;
        }
        select {
          margin-top: 30px;
        }
        .checkbox {
          margin-top: 15px;
        }
        .selectRegion {
          p {
            padding-left: 5px;
          }
        }
      }
      .selectCleaning {
        margin-bottom: 20px;
      }
      .right {
        display: flex;
        flex-direction: column;
        .workingTime {
          margin-bottom: 30px;
          p {
            margin: 16px 0 8px;
          }
          @media only screen and (min-width: 350px) {
            text-align: center;
          }
          .title {
            display: none;
            @media only screen and (min-width: 700px) {
              width: 415px;
              display: flex;
              justify-content: flex-end;
              gap: 30px;
              margin: 10px auto;
            }
          }
        }
      }
      .options {
        button {
          margin: auto;
        }
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        gap: 16px;
      }
    }

    .map {
      margin-top: 30px;
      display: flex;
      flex-direction: column;
      span {
        font-family: $font-primary;
        min-height: 22px;
        margin-bottom: 10px;
        padding-bottom: 0;
        font-size: 1rem;
        color: $mine-shaft;
        padding-left: 5px;
      }
      .coords {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        gap: 20px;
        div {
          margin-bottom: 0;
          label {
            color: gray;
          }
        }
        margin-bottom: 30px;
      }
    }
  }
  .error {
    p {
      color: $terracotta;
    }
  }
  @media only screen and (min-width: 992px) {
    padding: 20px 25px;
    box-shadow: 0px 2px 6px #0000000a;
    form {
      .content {
        flex-direction: row;
        justify-content: space-between;
        gap: 20px;
        .inputFields {
          width: 50%;
          input,
          select {
            width: 100%;
          }
          textarea {
            width: 100%;
          }
          .checkbox {
            input {
              width: 0;
            }
          }
          .location {
            .input {
              margin-bottom: 0 !important;
            }
            input {
              margin-bottom: 0 !important;
            }
          }
        }
        .right {
          .workingTime {
            p {
              margin: 0 0 8px;
            }
          }
          .options {
            margin-top: auto;
            button {
              margin: auto 0 0 auto;
            }
          }
        }
      }
    }
  }
  @media only screen and (min-width: 1300px) {
    padding: 20px 40px;
  }
}
