@import "./../../../../_styles/variables";

.wrapper {
  display: flex;
  flex-direction: column;
  height: calc(100% - 20px);
  .header {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;

    p {
      font-size: 1.125rem;
      cursor: pointer;
      font-family: $font-primary;
    }

    .tabActive {
      color: $color-text;
      font-weight: bold;
      display: flex;
      padding: 5px 10px;
      margin-left: 15px;
      text-transform: capitalize;
      border-bottom: 2px solid $border-active;
    }

    .tabInactive {
      font-weight: bold;
      display: flex;
      padding: 5px 10px;
      margin-left: 15px;
      text-transform: capitalize;
      color: $link-default;

      &:hover {
        color: $color-text;
        border-bottom: 2px solid $border-active;
      }
    }
  }
  .contentWrapper {
    overflow: hidden;
    height: 100%;
  }

  @media only screen and (min-width: 600px) {
    .header {
      flex-direction: row;
    }
  }
}
