.customForm {
  position: absolute;
  top: 20px;
  padding-bottom: 15px;
  width: 90%;
}
.customOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(5, 16, 20, 0.53);
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: auto;
}
.modalFormBody {
  position: relative;
}
.closeForm {
  position: absolute;
  right: 10px;
  top: 10px;
  cursor: pointer;
  z-index: 99999;

  i {
    font-size: large;
  }
}

.sm {
  max-width: 300px;
}

.md {
  max-width: 500px;
}

.lg {
  max-width: 700px;
}

.xlg {
  max-width: 1200px;
}
.xxlg {
  max-width: unset;
}
