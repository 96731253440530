@import "../../../_styles/variables";

.wrapper {
  display: flex;
  flex-direction: column;
  padding: 20px;
  height: calc(100% - 70px);
  gap: 5px;

  @media only screen and (min-width: 500px) {
    padding: 0 20px;
  }

  h2 {
    color: $color-text;
  }

  .left,
  .right {
    &::-webkit-scrollbar {
      width: 7px;
      height: 7px;
      padding: 5px;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 7px;
      --webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
      background: $casper;
    }
  }

  .left {
    padding: 20px;
    box-shadow: 0px 2px 6px #0000000a;
    border-radius: 4px;
    overflow-y: auto;

    .header {
      display: flex;

      p {
        font-size: 1.125rem;
        font-family: $font-primary;
        cursor: pointer;
      }

      .tabActive {
        color: $color-text;
        font-weight: bold;
        display: flex;
        padding: 5px 10px;
        text-transform: capitalize;
        border-bottom: 2px solid $border-active;
      }

      .tabInactive {
        font-weight: bold;
        display: flex;
        text-transform: capitalize;
        color: $link-default;
        padding: 5px 10px;

        &:hover {
          color: $color-text;
          border-bottom: 2px solid $border-active;
        }
      }
    }

    table {
      thead,
      tbody {
        th,
        td {
          &:first-child {
            padding: 10px 5px;
            width: 220px;
          }
        }
      }

      tbody {
        tr {
          td {
            max-width: 150px;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            padding-right: 10px;
          }

          &:hover {
            background-color: $botticelli;
            cursor: pointer;
          }
        }
      }
    }

    .selected {
      background-color: $botticelli;
    }
  }

  .right {
    box-shadow: 0px 2px 6px #0000000a;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-y: auto;

    .error {
      p {
        color: $terracotta;
      }
    }
    p {
      border-bottom: 1px solid $botticelli;
      width: 100%;
      padding: 10px;
      text-align: center;
    }
    .content,
    .usaContent {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      width: 100%;
      padding: 20px 0;
      @media only screen and (min-width: 500px) and (max-width: 992px) {
        grid-template-columns: repeat(4, 1fr);
      }
      @media only screen and (min-width: 1300px) {
        grid-template-columns: repeat(4, 1fr);
      }
      .code {
        padding: 10px;
        border-bottom: 1px solid $botticelli;
        &:nth-child(2n) {
          background-color: $botticelli;
          border-bottom: 1px solid $white;
        }
        &:hover {
          cursor: pointer;
          background-color: $casper;
        }
      }
      .activeCode {
        @extend .code;
        background-color: $casper !important;
      }
    }
    .usaContent {
      grid-template-columns: repeat(1, 1fr);

      @media only screen and (min-width: 500px) and (max-width: 992px) {
        grid-template-columns: repeat(2, 1fr);
      }
      @media only screen and (min-width: 992px) {
        grid-template-columns: repeat(1, 1fr);
      }
      @media only screen and (min-width: 1300px) {
        grid-template-columns: repeat(2, 1fr);
      }
    }

    .options {
      max-width: 250px;
      display: flex;
      justify-content: space-around;
      gap: 20px;
      @media only screen and (max-width: 400px) {
        flex-direction: column;
      }

      button {
        max-width: 70px;
        margin-top: 5px;
      }
    }
    .noData {
      text-align: center;
      padding: 100px 0 100px 0;

      span {
        font-size: 1.188rem;
        color: $link-default;
        font-family: $font-primary-bold;
      }
    }
  }

  @media only screen and (min-width: 992px) {
    flex-direction: row;
    justify-content: space-between;

    .left {
      width: 60%;
      background: $white;

      .header {
        .tabActive {
          margin-left: 25px;
          padding: 5px 20px;
        }

        .tabInactive {
          margin-left: 25px;
          padding: 5px 20px;
        }
      }
    }

    .right {
      width: 40%;
      background: $white;
      position: relative;
      padding: 15px;
      div {
        width: 100%;
      }

      .options {
        button {
          max-width: 100px !important;
        }

        & > div {
          display: flex;
          justify-content: center;
        }

        .submit {
          display: flex;
        }
      }
    }
  }

  @media only screen and (min-width: 1300px) {
  }
}
