@import "../../_styles/variables";

.wrapper {
  background: $bg-primary;
  height: 100vh;
  box-shadow: 2px 0px 6px #00000029;
  z-index: 1;
  position: fixed;
  width: 50px;

  &::-webkit-scrollbar {
    width: 7px;
    height: 7px;
    padding: 5px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 7px;
    --webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
    background: $casper;
  }

  span {
    font-size: 1rem;
  }

  ul {
    display: flex;
    flex-direction: column;
    height: 100vh;
    position: relative;
    li {
      cursor: pointer;

      svg {
        width: 20px;
        height: 27px;
      }
    }
  }

  .logo {
    .normalLogo {
      display: none;
    }

    img {
      width: 100%;
      height: 100%;
      margin: 10px 0;
      padding: 3px;
    }
  }

  .activeLinks {
    color: $white;
  }

  .activeLinkParent {
    @extend .activeLinks;
    background-color: $link-active;
  }
  .activeLinkTransactions {
    @extend .activeLinks;
    background-color: $dark-glacier;
  }
  .activeLinkTransactionsUL {
    height: unset !important;
  }
  .parent {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50px;
    width: 50px;

    span {
      padding: 0 10px 0 15px;
      letter-spacing: 0.524px;
    }

    .icon {
      width: 20px;
      div {
        display: flex;
        justify-content: center;
      }
    }

    .name {
      display: none;
    }

    &:hover {
      background: $link-active;
    }
    svg {
      color: white;
    }
  }
  .child {
    @extend .parent;
  }
  .logoutLi {
    position: absolute;
    bottom: 0;
    width: 100%;
  }
  .logout {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50px;
    width: 50px;

    svg {
      width: 20px;
      height: 27px;
    }

    .name {
      display: none;
    }

    &:hover {
      background: $link-active;
    }
  }

  .activeParent {
    span {
      color: $white;
      font-family: $font-primary-bold;
    }
  }

  @media only screen and (min-width: 992px) {
    width: 300px;

    ul {
      li {
        margin-bottom: 10px;
      }
    }

    .logo {
      .normalLogo {
        display: block;
      }

      .smallLogo {
        display: none;
      }

      img {
        padding: 55px;
        margin: 0;
      }
    }

    .parent {
      justify-content: initial;
      width: initial;
      height: initial;
      padding: 10px 30px 10px 30px;

      .name {
        display: flex;
        align-items: center;
        margin-left: 10px;

        span {
          white-space: nowrap;
          color: $white;
        }
      }
    }
    .parentTransactions {
      margin-bottom: 10px;
    }
    .child {
      padding: 10px 30px 10px 50px;
    }
    .logout {
      // margin-top: 100%;
      justify-content: initial;
      width: initial;
      height: initial;
      padding: 15px 30px 15px 30px;

      .name {
        color: $white;
        white-space: nowrap;
        display: initial;
        margin-left: 15px;

        span {
          white-space: nowrap;
          color: $white;
        }
      }
    }
    .support {
      padding: 15px 30px 15px 30px;
      margin-left: 15px;
      a {
        color: white;
      }
    }
  }
}
