@import "../../../../_styles//variables";

.wrapper {
  height: 100%;
  padding: 10px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  gap: 20px;
  .selectUser,
  .loadingData {
    text-align: center;
    padding: 100px;

    span {
      font-size: 1.188rem;
      color: $link-default;
      font-family: $font-primary-bold;
    }
  }
  h3 {
    text-align: center;
  }
  .containers {
    height: 100%;
    overflow: auto;
    gap: 10px;
    display: flex;
    flex-direction: column;
  }
  .container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: max-content;
    padding: 15px;
    // margin: 0 5px;
    border-radius: 5px;
    border: 1px solid transparentize($gray, 0.7);
    box-shadow: 0px 2px 6px #0000000a;
    background-color: #f4f3f3;
    .content {
      display: flex;
      flex-direction: column;
      .title {
        font-size: 20px;
        font-weight: 600;
        color: black !important;
      }
      span {
        font-size: 16px;
        font-weight: lighter;
        color: rgb(19, 19, 19);
      }
      .bold {
        font-weight: 900;
        color: black !important;
      }
      .italic {
        font-style: italic;
      }
    }
    .button {
      svg {
        margin-top: auto;
        width: 30px;
        height: 30px;
      }
    }
  }

  .toogle {
    display: flex;
    flex-direction: row;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    align-items: center;
    background-color: $wild-sand;
    width: max-content;
    height: max-content;
    margin-top: 15px;
    border-radius: 8px;
  }
}
