@import "./../../../_styles/variables";

.wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;

  .header {
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-between;
    gap: 20px;

    .icon {
      span {
        font-size: 1rem;
        font-family: $font-primary-medium;
      }

      div {
        display: flex;
        align-items: center;
        span:nth-of-type(1) {
          font-size: 3rem;
        }

        svg {
          margin-left: 15px;
          height: 40px;
          width: 60px;
          path {
            &:first-child {
              fill: $glacier;
              stroke: $glacier;
            }
            &:last-child {
              fill: $white;
            }
            stroke-width: 3px;
          }
        }
      }
    }

    @media only screen and (min-width: 500px) {
      flex-direction: row;
      padding-top: 30px;
    }

    @media only screen and (min-width: 992px) {
      padding-left: 20px;
      padding-right: 20px;
      padding-top: 30px;
    }

    @media only screen and (min-width: 992px) and (max-width: 1150px) {
      flex-direction: column-reverse;
    }
  }

  h2 {
    color: $mine-shaft;
    margin: 10px 0 0;

    @media only screen and (min-width: 992px) {
      padding-left: 20px;
      padding-right: 20px;
    }
  }

  .searchAndFilter {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 16px;
    margin: 30px 0;

    .selector {
      width: 110px;
    }

    @media only screen and (min-width: 992px) {
      flex-direction: row;
      padding-left: 20px;
      padding-right: 20px;
    }
  }
}

.tableWrapper {
  min-height: 200px;

  &::-webkit-scrollbar {
    width: 7px;
    height: 7px;
    padding: 5px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 7px;
    --webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
    background: $casper;
  }
}

.pagination {
  padding: 15px;
  margin-top: auto;
}
