@import '../../_styles/variables';

.date-picker-wrapper {
    width: 100%;
}

.react-datepicker-wrapper {
    width: 81%;
}

.react-datepicker__header {
    background-color: $whiteSmoke !important;
    border-bottom: 3px solid $botticelli !important;
}

.react-datepicker__month {
    margin: 0 !important;
    background-color: $whiteSmoke;
}

.react-datepicker__day-name, .react-datepicker__day, .react-datepicker__time-name {
    margin: 0.3rem !important;
}

.react-datepicker__day--selected {
    background-color: $casal !important;
}

.react-datepicker__navigation-icon::before {
    width: 6px !important;
    height: 6px !important;
}

.react-datepicker__current-month, .react-datepicker-time__header, .react-datepicker-year-header {
    font-family: $font-primary-bold;
}

/* Cleaning */

.label {
    font-size: 0.875rem;
    color: $gray;
    padding: 0;
}
.cleaning {
    margin-top: 10px;
    height: 50px;
    width: 100%;
    font-size: 0.875rem;
    background-color: white;
    border: 1px solid $gray;
    cursor: pointer;
    border-radius: 10px;
    text-align: left;
    padding-left: 15px;
    padding-top: 15px;
}

/* Distribution */

.distribution-wrapper {
    cursor: pointer;

   div {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 100%;

    .distribution-text {
        font-size: 1rem;
        color: $mine-shaft;
        margin-left: 20px;
    }

    img {
        width: 20px;
        height: 20px;
    }
   }
}
.distribution-wrapper-disabled{
    @extend .distribution-wrapper;
    cursor: not-allowed;
}