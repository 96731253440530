.wrapper_small {
  border: 1px solid #eaeaea;
  border-radius: 8px;
  padding: 24px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  min-height: 140px;
  min-width: 200px;

  .header {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    align-content: center;
    justify-content: center;
    gap: 10px;
    p {
      margin: 0;
      font-size: 16px;
      color: #626262;
      font-weight: 500;
      text-align: center;
    }
    .imgContainer {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      overflow: hidden;
      border: none;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
  div {
    padding: 0;
  }
  .content {
    text-align: center;
    font-size: 36px;
    font-weight: 700;
  }
}

.wrapper_large {
  @extend .wrapper_small;
  min-height: 175px;
  min-width: 250px;
  .header {
    p {
      font-size: 20px;
    }
  }
  .content {
    font-size: 48px;
  }
}

.help {
  position: absolute;
  top: -3px;
  right: -20px;
  svg {
    width: 15px;
    height: 15px;
  }
}
.tooltip_left {
  z-index: 4;
  padding: 5px 10px 8px !important;
  background-color: #4a5568;
  color: white;
  position: absolute;
  width: 250px;
  right: -5px;
  bottom: 25px;
  border-radius: 10px;
}
