@import "../../_styles/variables";

.common {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  align-content: center;
  border: 2px solid rgba(112, 112, 112, 0.7);

  input {
    background-color: transparent;
    box-sizing: border-box;
    color: $mine-shaft;
    outline: none;
    border: none;
    width: 100%;
    cursor: text;
  }

  ::placeholder {
    color: $mine-shaft;
    font-weight: 500;
    opacity: 0.4;
  }
}

.search {
  @extend .common;
  height: 35px;
  border-radius: 3px;
  max-width: 300px;

  input {
    line-height: 23px;
    cursor: text;
    font-size: 1.125rem;
  }

  ::placeholder {
    font-size: 1.125rem;
  }

  .icon {
    margin: 7px;
    width: 16px;
    height: 16px;
  }

  .inputBox {
    padding: 5px;
    width: calc(100% - 35px);
  }
}

.transactionSearch {
  @extend .search;
  max-width: 400px;

  input {
    font-size: 1rem;
  }

  ::placeholder {
    font-size: 0.875rem;
  }
}

.batchSearch {
  @extend .transactionSearch;
  max-width: 322px;
}

.restaurantSearch {
  @extend .search;
  max-width: unset;

  input {
    font-size: 1rem;
  }

  ::placeholder {
    font-size: 0.875rem;
  }
}