@import "../../_styles/variables";

.wrapper {
  height: 100%;
  padding: 10px;
  overflow: hidden;
  @media only screen and (min-width: 500px) {
    padding: 0 20px;
  }
  .header {
    text-align: center;
    @media only screen and (min-width: 992px) {
      padding: 0 30px;
    }
  }

  .contentWrapper {
    width: 100%;
    background-color: $white;
    height: 100%;
    box-shadow: 0px 2px 6px #0000000a;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    overflow: hidden;

    @media only screen and (min-width: 992px) {
      flex-direction: row;
    }

    .sideMenu {
      height: 100%;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 10px;
      background-color: $glacier;

      @media only screen and (min-width: 992px) {
        align-items: unset;
        flex-direction: column;
        width: 250px;
      }
      .header {
        font-size: 1.8rem;
        @media only screen and (min-width: 992px) {
          padding: 20px 0 10px;
        }
        color: $white;
        font-weight: bold;
        cursor: pointer;
      }
      .listItem {
        background-color: $glacier;
        color: $white;
        padding: 10px;
        padding-left: 15px;
        font-size: 1rem;
        cursor: pointer;
        width: 100%;
        text-align: center;
        @media only screen and (min-width: 992px) {
          width: unset;
          text-align: unset;
        }
        &:hover {
          background-color: $white;
          color: $glacier;
        }
      }
      .activeTab {
        @extend .listItem;
        background-color: $white;
        color: $glacier;
        border-bottom: 1px solid $glacier;
      }
    }
    .content {
      width: 100%;
      padding: 10px;
      overflow: auto;
    }
  }
}
