@import "../../_styles/variables";
.wrapper {
  display: inline-block;
  position: relative;
  width: 36px;
  height: 22px;
  .switch-checkbox {
    height: 0;
    width: 0;
    visibility: hidden;
  }

  .switch-label {
    position: absolute;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    width: 36px;
    height: 22px;
    background: $gray;
    border-radius: 100px;
    transition: background-color 0.2s;
  }

  .switch-label .switch-button {
    content: "";
    position: absolute;
    top: 2px;
    left: 2px;
    width: 18px;
    height: 18px;
    border-radius: 45px;
    transition: 0.2s;
    background: #fff;
  }

  .switch-checkbox:checked + .switch-label .switch-button {
    left: calc(100% - 2px);
    transform: translateX(-100%);
  }
  .switch-checkbox:checked + .switch-label {
    background: $casal;
  }
  .switch-label:active .switch-button {
    width: 22px;
  }
}

.toggleMD {
  @extend .wrapper;
}
.toggleLG {
  @extend .wrapper;
  width: 48px;
  height: 28px;
  .switch-label {
    width: 48px;
    height: 28px;
  }
  .switch-label .switch-button {
    width: 24px;
    height: 24px;
  }
  .switch-label:active .switch-button {
    width: 28px;
  }
}
