@import "../../../_styles/variables";

.tableWrapper {
  max-height: 400px;
  overflow: auto;
  height: 100%;

  &::-webkit-scrollbar {
    width: 7px;
    height: 7px;
    padding: 5px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 7px;
    --webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
    background: $casper;
  }

  @media only screen and (min-width: 992px) {
    max-height: unset;
  }

  .noRestaurantsMsg {
    text-align: center;
  }

  table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0;

    thead th {
      position: sticky;
      top: 0;
      color: $gray;
      font-size: 0.875rem;
      font-weight: normal;
      border-top: 1px solid transparentize($gray, 0.7);
      border-bottom: 1px solid transparentize($gray, 0.7);
      padding: 8px 12px;
      white-space: nowrap;
      cursor: pointer;
      background-color: $white;

      &:first-child {
        min-width: 250px;
      }

      &:nth-child(2n) {
        border: 1px solid transparentize($gray, 0.7);
      }

      @media only screen and (min-width: 500px) {
        padding: 12px 20px;
      }

      button {
        display: inline;
        width: 20px;
        margin: 0;
      }
    }

    tbody {
      tr {
        td {
          text-align: center;
          color: $mine-shaft;
          border-bottom: 1px solid transparentize($gray, 0.7);
          padding: 8px 12px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;

          &:nth-of-type(even) {
            border-left: 1px solid transparentize($gray, 0.7);
            border-right: 1px solid transparentize($gray, 0.7);
          }

          @media only screen and (min-width: 500px) {
            padding: 12px 20px;
          }
          .draft {
            color: $terracotta;
          }
        }

        .visible {
          svg {
            width: 23px;
            height: 23px;
          }
        }

        &:hover {
          background-color: $botticelli;
          cursor: pointer;
        }
      }

      .selected {
        background-color: $botticelli;
      }
    }
  }

  .loading {
    text-align: center;
    padding-top: 20px;
  }
}
