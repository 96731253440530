@import "../../../../_styles/variables";

.wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;

  .header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    h3 {
      font-size: 20px;
      span {
        font-size: 15px;
      }
    }
    .selectDisabled {
      cursor: not-allowed;
      opacity: 0.5;
    }
    .row {
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      margin-top: 10px;
      gap: 10px;

      .selectDate {
        width: 420px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        .checkboxMonthly {
          label {
            margin-bottom: 0;
          }
        }
        .selectMonth,
        .checkboxMonthly {
          width: 115px;
          div {
            margin-bottom: 0;
          }
        }
        .selectYear {
          width: 80px;
          div {
            margin-bottom: 0;
          }
        }
        .selectPeriod {
          width: 100px;
          div {
            margin-bottom: 0;
          }
        }
        .selectDisabled {
          cursor: not-allowed;
          opacity: 0.5;
        }
      }
    }

    @media only screen and (min-width: 560px) {
      .row {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
      }
    }

    @media only screen and (min-width: 992px) and (max-width: 1350px) {
      .row {
        flex-direction: column;
        align-items: flex-start;
        gap: 10px;
      }
    }
  }

  .noMembersMsg {
    text-align: center;
    padding-top: 20px;
  }
}
