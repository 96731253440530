@import "../../../_styles/variables";

.left {
    background-color: $white;
    width: 100%;
    padding: 20px;
    display: flex;
    flex-direction: column;
    box-shadow: 0px 2px 6px #0000000a;
    border-radius: 4px;
    overflow: auto;

    &::-webkit-scrollbar {
        width: 7px;
        height: 7px;
        padding: 5px;
    }

    &::-webkit-scrollbar-thumb {
        border-radius: 7px;
        --webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
        background: $casper;
    }

    .searchAndFilter {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin-top: 20px;
        gap: 20px;
        div{
            max-width: unset;
        }
      
    }

    .batchList {
        margin-top: 30px;
        overflow-x: auto;
        height: 100%;
        min-height: 200px;
        max-height: 540px;
        background-color: $wild-sand;

        @media only screen and (min-width: 992px) {
            max-height: unset;
        }

        &::-webkit-scrollbar {
            width: 7px;
            height: 7px;
            padding: 5px;
        }

        &::-webkit-scrollbar-thumb {
            border-radius: 7px;
            --webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
            background: $casper;
        }

        .closeButton {
            display: flex;
            flex-direction: row-reverse;
            margin-top: 30px;
            margin-right: 20px;
        }

        .titleBox {
            border-bottom: 1px solid $botticelli;
            height: 50px;
        }

        .block {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            padding-left: 10px;
            height: 60px;
            color: $mine-shaft;
            font-size: 12px;
            background-color: $wild-sand;
            border-bottom: 1px solid $mercury;
            min-width: 450px;

            .index {
                width: 10%;
                padding: 10px;
            }

            .batchId {
                width: 60%;
            }

            .blockButton {
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                padding-right: 20px;
                padding-left: 10px;

                button {
                    width: 86px;
                    height: 32px;
                    margin-bottom: 0;
                }
            }

            .deleteButton {
                margin-right: 10px;
                cursor: pointer;
            }
        }
    }

    .pagination {
        padding-top: 15px;
        margin-top: auto;
    }
}

.leftDetails {
    padding: 20px;
    min-width: 377px;
    background-color: $white;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    overflow: auto;

    .tableHeader {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;

        span:first-child {
            font-size: 1.5rem;
            font-family: $font-primary-bold;
        }

        span:nth-child(2) {
            font-size: 1rem;
            font-family: $font-primary-bold;
        }

        span:last-child {
            font-size: 0.75rem;
            color: $gray;
        }
    }

    .list {
        margin-top: 20px;
        padding: 0 10px 10px 10px;
        overflow: auto;
        height: 100%;
        background-color: $wild-sand;
        max-height: 540px;

        @media only screen and (min-width: 992px) {
            max-height: unset;
        }

        &::-webkit-scrollbar {
            width: 7px;
            height: 7px;
            padding: 5px;
        }

        &::-webkit-scrollbar-thumb {
            border-radius: 7px;
            --webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
            background: $casper;
        }

        .item {
            display: flex;
            align-items: center;
            flex-direction: row;
            justify-content: space-between;
            border-bottom: 1px solid $lightGray;
            padding: 10px;

            p {
                font-family: $font-primary-medium;
                font-size: 1rem;
                color: $color-text;
                margin: 0;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
            }

            .qrDiv {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                width: 100px;

                img {
                    width: 60px;
                    height: 60px;
                }

                .itemCheckbox {
                    label {
                        display: flex;
                        flex-direction: column;
                    }
                }
            }


        }

    }
}


.right {
    width: 100%;
    min-width: 200px;
    overflow: auto;

    .subColumn {
        display: flex;
        flex-direction: column-reverse;
        justify-content: space-between;
        gap: 40px;
    }

    &::-webkit-scrollbar {
        width: 7px;
        height: 7px;
        padding: 5px;
    }

    &::-webkit-scrollbar-thumb {
        border-radius: 7px;
        --webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
        background: $casper;
    }
}

.button {
    button {
        padding: 15px;
        width: 80%;
        margin-right: auto;
        margin-left: auto;
        max-width: 400px;
        margin-top: 20px;

        @media only screen and (min-width: 992px) {
            margin-top: 0;
        }
    }
}

@media only screen and (min-width: 1200px) {
    .left {
        width: 55%;
    }

    .leftDetails {
        width: 55%;
        overflow: auto;

        &::-webkit-scrollbar {
            width: 7px;
            height: 7px;
            padding: 5px;
        }

        &::-webkit-scrollbar-thumb {
            border-radius: 7px;
            --webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
            background: $casper;
        }
    }

    .right {
        width: 43%;
        overflow-y: auto;
        display: flex;
        flex-direction: column;
        gap: 30px;

        .button {
            margin: 15px 15px 0 15px;

            button {
                width: 100%;
                max-width: none;
                margin-bottom: 0;
            }
        }
    }
}

@media only screen and (min-width: 1300px) {
    .left {
        .searchAndFilter {
            flex-direction: row;
            justify-content: space-between;
            height: 34px;
            gap: 20px;
            div{
                max-width: unset;
            }
        }

        .selectYear {
            margin-bottom: 10px;
            display: flex;
            flex-direction: row-reverse;
            height: 35px;
            margin-top: 0;
        }

        .batchList {
            .closeButton {
                margin-right: 38px;
            }

            .block {
                font-size: 1rem;
                justify-content: space-between;

                .index {
                    padding: 5px;
                    text-align: center;
                }

                .batchId {
                    width: 70%;
                }
            }
        }
    }
}

.noResults {
    padding: 10px;
    text-align: center;
}