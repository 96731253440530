@import "./../../../../_styles/variables";

.wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;

  .selectYear {
    div {
      margin-left: auto;
    }
  }

  .list {
    margin-top: 30px;
    overflow-x: auto;
    max-height: 500px;
    height: 100%;

    @media only screen and (min-width: 992px) {
      max-height: unset;
    }

    background-color: $wild-sand;

    &::-webkit-scrollbar {
      width: 7px;
      height: 7px;
      padding: 5px;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 7px;
      --webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
      background: $casper;
    }

    .header {
      position: sticky;
      top: 0;
    }

    .block,
    .header {
      min-width: 400px;
      display: grid;
      grid-template-columns: 0.5fr 0.5fr 100px auto;

      align-items: center;
      padding-left: 10px;
      height: 60px;
      color: $mine-shaft;
      font-size: 12px;
      background-color: $wild-sand;
      border-bottom: 1px solid $mercury;

      p {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .blockRestaurant {
        padding: 10px;
        font-size: 14px;
        min-width: 155px;
      }

      .blockCreated {
        font-size: 14px;
        text-align: center;
        padding: 0 10px;
        min-width: 90px;
        align-content: center;
        align-items: center;
        cursor: pointer;
      }

      .blockButton {
        padding: 10px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        min-width: 106px;

        button {
          width: 86px;
          height: 32px;
          margin-bottom: 0;
        }
      }
    }

    .header {
      .blockId,
      .blockRestaurant,
      .blockStatus,
      .blockCreated {
        color: $black;
        font-size: 1.3em;
        font-weight: 600;
      }

      .blockStatus,
      .blockCreated {
        button {
          display: inline;
          width: 20px;
          margin: 0;
          background-color: $wild-sand;
        }
      }
    }
  }

  .searchAndSelect {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    gap: 10px;

    .select {
      width: 100%;

      div {
        max-width: unset;
      }
    }

    .buttons {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      gap: 20px;

      button {
        margin-bottom: 0;
      }
    }

    @media only screen and (min-width: 600px) {
      flex-direction: row;
      align-items: center;
    }

    @media only screen and (min-width: 992px) and (max-width: 1500px) {
      flex-direction: column;
      align-items: unset;
    }

    @media only screen and (min-width: 1500px) {
      flex-direction: row;
      align-items: center;
    }
  }
}

.noTransferList {
  text-align: center;
}

.pagination {
  padding-top: 15px;
  margin-top: auto;
}
