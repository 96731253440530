@import './../../../../../../_styles/variables';

.wrapper{
    padding: 30px;
    background-color: $white;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    p{
        margin-bottom: 20px;
    }
    button{
        margin-top: 50px;
    }
}