@import "../../_styles/variables";

.selectErr {
  color: $terracotta !important;
  margin: 0;
}

.wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 250px;
  background-color: $white;
  border-radius: 5px;
}

.label {
  padding: 0 10px 0 10px;
  width: 30%;
  word-wrap: break-word;
}

.selectPrimary {
  width: 100%;
  font-family: $font-primary;
  height: 22px;
  margin-bottom: 5px;
  padding-bottom: 0;
  font-size: 0.875rem;
  color: $gray;

  p {
    span {
      color: $terracotta !important;
    }
  }

  .selectPrimary__value-container {
    position: relative;
  }

  .selectPrimary__container:focus {
    outline: none;
  }

  .selectPrimary__control {
    border: none;
    background-color: none;
    min-height: 40px;
    padding: 5px;
    background-color: $botticelli;
    cursor: pointer;
    box-shadow: none;
    border-radius: 10px;

    &:focus {
      outline: none !important;
    }
  }

  .selectPrimary__single-value {
    font-size: 0.8rem;
    font-family: $font-primary;
    background-color: $botticelli;
    font-weight: 500;
    color: $mine-shaft;
  }

  .selectPrimary__option {
    text-align: left;
    color: $black;
    font-style: "normal";
    cursor: "pointer";
    font-weight: "regular";
    font-family: $font-primary;
    background: $white;

    &:active {
      color: $white;
    }

    &:hover {
      background-color: $botticelli;
      font-size: 0.8rem;
      font-weight: 500;
      color: $mine-shaft;
    }
  }

  .selectPrimary__menu {
    position: absolute;
    z-index: 1;
    width: 100%;
    top: 35px;
    z-index: 2;
    border-radius: 5px;
  }

  .selectPrimary__menu-list {
    padding-top: 0;
    padding-bottom: 0;
    font-size: small;
    background: $white;
    overflow: auto;
    max-height: 250px;
    overflow-x: hidden;
    border-radius: 5px;
    font-size: 0.8rem;
    font-family: $font-primary;

    &:hover {
      background-color: $botticelli;
    }
  }

  .selectPrimary__indicator-separator {
    display: none !important;
  }

  .selectPrimary__dropdown-indicator {
    color: $black;
  }

  .selectPrimary__placeholder {
    color: $mine-shaft;
    font-weight: 500;
    opacity: 0.4;
    font-family: $font-primary;
    font-size: 0.8rem;
  }
}

.selectSecondary {
  width: 100%;
  font-family: $font-primary;
  margin-bottom: 5px;
  padding-bottom: 0;
  font-size: 0.875rem;
  color: $gray;

  .selectSecondary__value-container {
    position: relative;
  }

  .selectSecondary__container:focus {
    outline: none;
  }

  .selectSecondary__control {
    border: none;
    background-color: none;
    min-height: 36px;
    background-color: $botticelli;
    cursor: pointer;
    box-shadow: none;
    border-radius: 3px;

    &:focus {
      outline: none !important;
    }
  }

  .selectSecondary__single-value {
    font-size: 0.8rem;
    font-family: $font-primary;
    background-color: $botticelli;
    font-weight: 500;
    color: $mine-shaft;
  }

  .selectSecondary__option {
    text-align: left;
    color: $black;
    font-style: "normal";
    cursor: "pointer";
    font-weight: "regular";
    font-family: $font-primary;
    background: $white;

    &:active {
      color: $white;
    }

    &:hover {
      background-color: $botticelli;
      font-size: 0.8rem;
      font-weight: 500;
      color: $mine-shaft;
    }
  }

  .selectSecondary__menu {
    position: absolute;
    z-index: 1;
    width: 100%;
    margin-top: 0;
    z-index: 2;
    border-radius: 5px;
  }

  .selectSecondary__menu-list {
    padding-top: 0;
    padding-bottom: 0;
    font-size: small;
    background: $white;
    overflow: auto;
    max-height: 250px;
    overflow-x: hidden;
    border-radius: 5px;
    font-size: 0.8rem;
    font-family: $font-primary;

    &:hover {
      background-color: $botticelli;
    }
  }

  .selectSecondary__indicator-separator {
    display: none !important;
  }

  .selectSecondary__dropdown-indicator {
    color: $black;
  }

  .selectSecondary__placeholder {
    color: $mine-shaft;
    font-weight: 500;
    opacity: 0.4;
    font-family: $font-primary;
    font-size: 0.8rem;
  }
}

.selectSecondaryWhiteBg {
  width: 100%;
  font-family: $font-primary;
  margin-bottom: 5px;
  padding-bottom: 0;
  font-size: 0.875rem;
  color: $gray;

  .selectSecondaryWhiteBg__value-container {
    position: relative;
  }

  .selectSecondaryWhiteBg__container:focus {
    outline: none;
  }

  .selectSecondaryWhiteBg__control {
    border: 1px solid #f4f3f3;
    background-color: none;
    min-height: 36px;
    background-color: $white;
    cursor: pointer;
    box-shadow: none;
    border-radius: 3px;

    &:focus {
      outline: none !important;
    }
  }

  .selectSecondaryWhiteBg__single-value {
    font-size: 0.8rem;
    font-family: $font-primary;
    background-color: $white;
    font-weight: 500;
    color: $mine-shaft;
  }

  .selectSecondaryWhiteBg__option {
    text-align: left;
    color: $black;
    font-style: "normal";
    cursor: "pointer";
    font-weight: "regular";
    font-family: $font-primary;
    background: $white;

    &:active {
      color: $white;
    }

    &:hover {
      background-color: $botticelli;
      font-size: 0.8rem;
      font-weight: 500;
      color: $mine-shaft;
    }
  }

  .selectSecondaryWhiteBg__menu {
    position: absolute;
    z-index: 1;
    width: 100%;
    margin-top: 0;
    z-index: 2;
    border-radius: 5px;
  }

  .selectSecondaryWhiteBg__menu-list {
    padding-top: 0;
    padding-bottom: 0;
    font-size: small;
    background: $white;
    overflow: auto;
    max-height: 250px;
    overflow-x: hidden;
    border-radius: 5px;
    font-size: 0.8rem;
    font-family: $font-primary;

    &:hover {
      background-color: $botticelli;
    }
  }

  .selectSecondaryWhiteBg__indicator-separator {
    display: none !important;
  }

  .selectSecondaryWhiteBg__dropdown-indicator {
    color: $black;
  }

  .selectSecondaryWhiteBg__placeholder {
    color: $mine-shaft;
    font-weight: 500;
    opacity: 1;
    font-family: $font-primary;
    font-size: 0.8rem;
  }
}

/* smaller for transaction page */
.selectTransaction {
  @extend .selectPrimary;

  .selectTransaction__control {
    border: 1px solid $mischka;
    background-color: none;
    min-height: 40px;
    padding: 5px;
    background-color: $white;
    cursor: pointer;
    box-shadow: none;
    border-radius: 5px;

    &:focus {
      outline: none !important;
    }

    &:hover {
      color: $emperor;
    }
  }

  .selectTransaction__single-value {
    font-size: 0.8rem;
    font-family: $font-primary;
    background-color: $white;
    font-weight: 500;
    color: $mine-shaft;
  }

  .selectTransaction__option {
    text-align: left;
    color: $black;
    font-style: "normal";
    cursor: pointer;
    font-weight: "regular";
    font-family: $font-primary;
    background: $white;

    &:active {
      color: $white;
    }

    &:hover {
      background-color: $botticelli;
      font-size: 0.8rem;
      font-weight: 500;
      color: $mine-shaft;
    }
  }

  .selectTransaction__menu {
    position: absolute;
    z-index: 1;
    width: 100%;
    top: 35px;
    z-index: 2;
    border-radius: 5px;
  }

  .selectTransaction__menu-list {
    padding-top: 0;
    padding-bottom: 0;
    font-size: small;
    background: $white;
    overflow: auto;
    max-height: 250px;
    overflow-x: hidden;
    border-radius: 5px;
    font-size: 0.8rem;
    font-family: $font-primary;

    &:hover {
      background-color: $botticelli;
    }
  }

  .selectTransaction__indicator-separator {
    display: none !important;
  }

  .selectTransaction__dropdown-indicator {
    color: $black;

    &:hover {
      color: $emperor;
    }
  }

  .selectTransaction__placeholder {
    color: $mine-shaft;
    font-weight: 500;
    opacity: 0.4;
    font-family: $font-primary;
    font-size: 0.8rem;
  }
}

.selectYear {
  width: 85px;
  font-family: $font-primary;
  margin-bottom: 5px;
  padding-bottom: 0;
  font-size: 1rem;
  color: $mine-shaft;

  .selectYear__value-container {
    position: relative;
  }

  .selectYear__container:focus {
    outline: none;
  }

  .selectYear__control {
    border: none;
    min-height: 36px;
    background-color: transparent;
    cursor: pointer;
    box-shadow: none;
    border-radius: 3px;

    &:focus {
      outline: none !important;
    }
  }

  .selectYear__single-value {
    font-size: 0.8rem;
    font-family: $font-primary;
    background-color: $input-bg-secondary;
    font-weight: 500;
    color: $mine-shaft;
  }

  .selectYear__option {
    text-align: left;
    color: $mine-shaft;
    font-style: "normal";
    cursor: "pointer";
    font-weight: "regular";
    font-family: $font-primary;
    background: $white;

    &:active {
      color: $white;
    }

    &:hover {
      background-color: $gray;
      font-size: 0.8rem;
      font-weight: 500;
      color: $mine-shaft;
    }
  }

  .selectYear__menu {
    position: absolute;
    z-index: 1;
    width: 100%;
    margin-top: 0;
    z-index: 2;
    border-radius: 5px;
  }

  .selectYear__menu-list {
    padding-top: 0;
    padding-bottom: 0;
    font-size: small;
    background: $white;
    overflow: auto;
    max-height: 250px;
    overflow-x: hidden;
    border-radius: 5px;
    font-size: 0.8rem;
    font-family: $font-primary;

    &:hover {
      background-color: $input-bg-secondary;
    }
  }

  .selectYear__indicator-separator {
    display: none !important;
  }

  .selectYear__dropdown-indicator {
    color: $black;
  }

  .selectYear__placeholder {
    color: $mine-shaft;
    font-weight: 500;
    font-family: $font-primary-bold;
    font-size: 0.8rem;
  }
}

.selectGray {
  font-family: $font-primary;
  margin-bottom: 5px;
  padding-bottom: 0;
  font-size: 0.875rem;

  .selectGray__value-container {
    position: relative;
  }

  .selectGray__container:focus {
    outline: none;
  }

  .selectGray__control {
    border: none;
    background-color: none;
    min-height: 36px;
    background-color: $input-bg-secondary;
    cursor: pointer;
    box-shadow: none;
    border-radius: 3px;

    &:focus {
      outline: none !important;
    }
  }

  .selectGray__single-value {
    font-size: 0.8rem;
    font-family: $font-primary;
    background-color: $input-bg-secondary;
    font-weight: 500;
    color: $mine-shaft;
  }
  .selectGray__multi-value {
    background-color: $mercury;
    .selectGray__multi-value__label {
      font-size: 1rem;
    }
  }

  .selectGray__option {
    text-align: left;
    color: $black;
    font-style: "normal";
    cursor: "pointer";
    font-weight: "regular";
    font-family: $font-primary;
    background: $white;

    &:active {
      color: $white;
    }

    &:hover {
      background-color: $input-bg-secondary;
      font-size: 0.8rem;
      font-weight: 500;
      color: $mine-shaft;
    }
  }

  .selectGray__menu {
    position: absolute;
    z-index: 1;
    width: 100%;
    margin-top: 0;
    z-index: 2;
    border-radius: 5px;
  }

  .selectGray__menu-list {
    padding-top: 0;
    padding-bottom: 0;
    font-size: small;
    background: $white;
    overflow: auto;
    max-height: 250px;
    overflow-x: hidden;
    border-radius: 5px;
    font-size: 0.8rem;
    font-family: $font-primary;

    &:hover {
      background-color: $input-bg-secondary;
    }
  }

  .selectGray__indicator-separator {
    display: none !important;
  }

  .selectGray__dropdown-indicator {
    color: $black;
  }

  .selectGray__placeholder {
    color: $mine-shaft;
    font-weight: 500;
    font-family: $font-primary-bold;
    font-size: 0.8rem;
  }
}
