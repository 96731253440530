@import "./../../../../_styles/variables";

.wrapper {
    padding: 30px;
    background-color: $white;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

    p {
        margin-bottom: 20px;
        font-size: 1.2em;
        font-weight: bold;
    }

    form {
        margin-top: 20px;
        display: flex;
        flex-direction: column;
        align-items: center;

    }
}