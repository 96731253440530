@import "../../_styles/variables";

.wrapper {
  textarea::-webkit-outer-spin-button,
  textarea::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
  }

  textarea[type="number"] {
    -moz-appearance: textfield; /* Firefox */
  }

  .textarea {
    background: $white;
    border-radius: 5px;
    border: none;
    width: 100%;
    font-size: 0.875rem;
    color: $color-secondary;
    padding: 15px 20px;
    font-family: $font-primary;
    outline: none;
    margin: 10px 0;

    &::placeholder {
      color: $black;
    }
    &:disabled {
      cursor: not-allowed;
    }

    &:active {
      outline: none;
      background-color: $botticelli !important;
    }

    &:focus {
      outline: none;
    }

    &[type="password"] {
      letter-spacing: 1px;
    }

    &[type="password"]::placeholder {
      letter-spacing: initial;
    }
  }

  .desc {
    display: flex;
    margin-bottom: 10px;
  }

  label {
    text-align: left;
    display: block;
    padding-left: 5px;
    color: $black;
    padding-bottom: 15px;
    span {
      color: $terracotta !important;
    }
  }
}

.textareaDefault {
  @extend .wrapper;
  margin-bottom: 25px;
  margin-top: 15px !important;
  border-radius: 10px;

  textarea {
    width: 100%;
    height: 200px;
    background-color: $botticelli;
    border-radius: 10px;
    resize: none;
    padding: 15px;
    font-family: $font-primary;
    border: none;
    &:focus {
      outline: none;
    }

    &::placeholder {
      color: $mine-shaft;
      opacity: 0.4;
      font-weight: 300;
    }
  }

  label {
    font-family: $font-primary;
    height: 22px;
    margin-bottom: 5px;
    padding-bottom: 0;
    font-size: 0.875rem;
    color: $gray;
  }

  @media only screen and (min-width: 992px) {
    margin-top: 0;
    margin-bottom: 25px;
  }
}
.textareaWhite {
  @extend .textareaDefault;
  textarea {
    background-color: $input-bg-secondary;
  }
  label {
    color: unset;
  }
  @media only screen and (min-width: 992px) {
    width: 100%;
    margin-top: 0;
    margin-bottom: 25px;
  }
}

.borderRed {
  .textarea,
  textarea {
    border: 2px solid $terracotta;
    border-radius: 3px;
  }
}
