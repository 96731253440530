h2 {
  text-align: center;
  padding: 5px;
  margin: 0;
}
.wrapper {
  display: flex;
  flex-direction: column;
  gap: 20px;
  overflow: auto !important;
  background-color: white;
  border-radius: 10px;
  padding: 20px 30px;
}
