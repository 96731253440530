@import "../../../../_styles/variables";

.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 20px;
  padding: 10px;
  width: 100%;
}
.chartWrapper {
  padding: 10px;
  padding-bottom: 80px;
  border: 1px solid #eaeaea;
  border-radius: 8px;
  h2 {
    text-align: left;
  }
}
.chart {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 15px 30px;
  .column {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 250px;
  }
  .chartRow {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    gap: 10px;
    padding-left: 10px;
    justify-content: space-between;
    border-bottom: 1px solid #f1f1f1;
    background: repeating-linear-gradient(
      to bottom,
      #f1f1f1,
      #f1f1f1 1px,
      transparent 1px,
      transparent 50px
    );
  }
}

.bin {
  position: relative;
  display: flex;
  width: 100%;
  height: 250px;
  &:hover {
    cursor: pointer;
    .bar {
      background-color: #225060;
    }
  }
  .bar {
    margin-top: auto;
    width: 100%;
    background-color: #8bb8c6;
  }
  .name {
    text-align: center;
    position: absolute;
    width: 100%;
    top: 250px;
    height: 80px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    gap: 5px;
    span {
      color: #225060 !important;
      font-size: 18px;
      font-weight: bold;
      display: -webkit-box;
      -webkit-line-clamp: 2; /* Limit to 2 lines */
      line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: normal;

      &:hover {
        overflow: visible;
        -webkit-line-clamp: unset;
        white-space: normal;
      }
    }
    p {
      font-size: 22px;
      font-weight: bold;
      margin: 0;
    }
  }
}
.selectedBin {
  background-color: #225060 !important;
}

.centerText {
  text-align: center;
}
.infoContainer {
  position: absolute;
  top: -10px;
  left: -5px;
}
.info {
  padding: 10px;
  border: 1px solid #d8d8d8;
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  h2 {
    text-align: left;
    padding: 0 !important;
    margin-bottom: 10px;
  }
  h3 {
    margin: 0;
  }
  .percentage {
    margin-top: 20px;
    background-color: #225060;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    font-size: 20px;
    width: 100px;
    height: 70px;
  }
  .binInfo {
    padding: 5px;
    flex-direction: column;
    display: flex;
    justify-content: space-between;
    gap: 10px;
    .bold {
      font-weight: bold;
    }
  }
  .lineChart {
    width: 80%;
    padding: 0 20px;
  }
}
