@import "../../../_styles/variables";

.wrapper {
  .section {
    padding-bottom: 10px;
    margin-left: 20px;
    border-bottom: 1px solid $casper;
    .header {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      gap: 10px;
      svg {
        cursor: pointer;
        height: 20px;
        width: 20px;
      }
    }
    .content {
      display: flex;
      flex-direction: column;
      .options {
        display: flex;
        flex-direction: row;
        margin-left: auto;
        gap: 10px;
        button {
          width: 80px;
        }
      }
      .table {
        position: relative;
        table {
          min-width: 700px;
        }
        .loading {
          position: absolute;
          width: 100%;
          height: 100%;
          top: 0;
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: transparentize($white, 0.3);
        }
      }
    }
  }
}
