@import "../../../../_styles/variables";

.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  .row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
    gap: 20px;
  }
}
