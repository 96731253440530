@import "./../../../../_styles/variables";

.wrapper {
    padding: 20px;
    height: 100%;
    overflow: auto;
    min-width: 350px;

    &::-webkit-scrollbar {
        width: 7px;
        height: 7px;
        padding: 5px;
    }

    &::-webkit-scrollbar-thumb {
        border-radius: 7px;
        --webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
        background: $casper;
    }
    .close{
        display: flex;
        justify-content: flex-end;
        margin-bottom: 10px;
        cursor: pointer;
        img{
            width: 20px;
        }
    }
    .header {
        display: flex;
        justify-content: space-between;
        margin-bottom: 30px;

        img {
            width: 120px;
        }
    }
    .content {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        gap: 20px;
        margin-bottom: 30px;

        .restaurantInfo {
            height: 110px;
            border-radius: 3px;
            background-color: $input-bg-secondary;
            padding: 10px;
            overflow: auto;
            span{
                font-size: 1.25em;
                font-weight: bold;
                margin-top: 0;
            }
            .address{
                font-size: 0.85em;
            }
            .phone{
                margin-top: 0;
                margin-bottom: 0;
                font-size: 0.85em;
            }
            &::-webkit-scrollbar {
                width: 7px;
                height: 7px;
                padding: 5px;
            }

            &::-webkit-scrollbar-thumb {
                border-radius: 7px;
                --webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
                background: $casper;
            }
        }
        .containers {
            height: 160px;
            border-radius: 3px;
            background-color: $input-bg-secondary;
            padding: 10px;
            overflow: auto;
            .info{
                display: flex;
                justify-content: space-between;
                gap:15px;
                p{
                    margin-top: 0;
                }
            }
            &::-webkit-scrollbar {
                width: 7px;
                height: 7px;
                padding: 5px;
            }

            &::-webkit-scrollbar-thumb {
                border-radius: 7px;
                --webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
                background: $casper;
            }
        }
        .secureCode {
            width: 150px;
        }

        div {
            label {
                color: $gray !important;
            }
        }
    }
    
    .buttons {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        gap: 10px;
    }
}
