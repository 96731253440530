@import "../../../_styles/variables";

.wrapper {
  width: 100%;
  background-color: $white;
  display: flex;
  flex-direction: column;
  padding: 10px;

  &::-webkit-scrollbar {
    width: 7px;
    height: 7px;
    padding: 5px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 7px;
    --webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
    background: $casper;
  }

  .left {
    width: 100%;

    h1 {
      text-align: center;
    }

    input {
      border: 1px solid $gray;
      background-color: $white;
    }

    & > div {
      margin-top: 30px;
      @media only screen and (min-width: 992px) {
        margin-top: 50px;
      }
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      & > div > label {
        flex-direction: row-reverse;
        align-items: center;
        justify-content: flex-end;

        & > span {
          margin-right: 20px;
        }
      }

      .checkboxAndButtons {
        @media only screen and (min-width: 992px) {
          margin-top: 30px;
        }
      }

      .buttonsWrapper {
        margin-top: 30px;
        @media only screen and (min-width: 992px) {
          margin-top: 100px;
        }
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        flex-wrap: wrap;

        button {
          margin-top: 20px;
        }

        .twoButtons {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          justify-content: space-between;

          button {
            margin-right: 20px;
          }
        }
      }
    }
  }

  .right {
    padding: 30px;
    @media only screen and (min-width: 500px) {
      min-width: 377px;
    }

    .containerButtons {
      display: flex;
      flex-direction: row;
      margin-left: auto;
      margin-right: auto;
      justify-content: center;
      align-items: center;
      background-color: $wild-sand;
      @media only screen and (min-width: 350px) {
        width: max-content;
      }
      height: max-content;
      margin-top: 20px;
      border-radius: 15px;
    }

    .tableHeader {
      margin-top: 20px;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: center;

      span:first-child {
        font-size: 1.5rem;
        font-family: $font-primary-bold;
      }

      span:nth-child(2) {
        font-size: 1rem;
        font-family: $font-primary-bold;
      }

      span:last-child {
        font-size: 0.75rem;
        color: $gray;
      }
    }

    .camera {
      margin-top: 20px;
      margin-bottom: 20px;
    }

    .listContainer {
      padding-bottom: 10px;
      @media only screen and (min-width: 600px) {
        padding-bottom: 20px;
      }
    }

    .list {
      margin-top: 20px;
      padding: 10px;
      overflow: auto;
      height: auto;
      background-color: $wild-sand;

      &::-webkit-scrollbar {
        width: 7px;
        height: 7px;
        padding: 5px;
      }

      &::-webkit-scrollbar-thumb {
        border-radius: 7px;
        --webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
        background: $casper;
      }

      .item {
        display: grid;
        align-items: center;
        grid-template-columns: 4fr 2fr 1fr;
        border-bottom: 1px solid $lightGray;
        padding: 10px;

        p {
          font-family: $font-primary-medium;
          font-size: 1rem;
          color: $color-text;
          margin: 0;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }

        img {
          width: 60px;
          height: 60px;
        }
      }
    }
  }

  .noScannedCodes {
    text-align: center;
  }

  .emptyList {
    height: 149px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0;
  }

  .scanDate,
  .numberOfScanned {
    font-size: 1.125rem;
    font-family: $font-primary-bold;
  }

  @media only screen and (min-width: 992px) {
    padding: 30px;
    display: flex;
    flex-direction: row;
    overflow: auto;

    .left {
      width: 50%;

      h1 {
        margin-bottom: 90px;
      }

      & > div {
        width: 80%;
        margin-left: auto;
        margin-right: auto;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;

        input {
          width: 90% !important;
        }

        .checkboxAndButtons {
          width: 80%;

          input {
            margin-top: 50px;
            width: 0 !important;
          }
        }

        .buttonsWrapper {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          max-width: 302px;
          margin-left: auto;
          margin-right: auto;

          button {
            width: 100%;
            max-width: 302px;
          }

          .twoButtons {
            display: flex;
            flex-wrap: nowrap;
            justify-content: space-between;

            button {
              width: 45%;
              margin-top: 20px;
              margin-right: 0;
            }
          }
        }
      }
    }

    .right {
      width: 50%;
      min-width: auto;
    }
  }
}
