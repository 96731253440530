@import "../../../_styles/variables";

.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 360px;
  .loading {
    margin: auto;
  }
  .stripeButton {
    width: 100%;
    margin-top: 20px;
    background-color: $system-blue;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    color: white;
    cursor: pointer;
    border: none;
    padding: 10px;
    font-size: 1rem;
  }
  .stripeButtonRed {
    @extend .stripeButton;
    background-color: $terracotta;
  }
  .disabledRed {
    background-color: $terracotta-dark !important;
    cursor: not-allowed !important;
  }
  .form {
    width: 400px;
    display: flex;
    flex-direction: column;
    height: 100%;

    h3 {
      text-align: center;
      margin-bottom: 30px;
    }
    .disabled {
      background-color: $system-blue-disabled !important;
      cursor: not-allowed !important;
    }
    .cardElement {
      border: 1px solid $casal;
      border-radius: 5px;
      padding: 10px;
    }
    .footer {
      text-align: center;
    }
  }
  .paymentWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    gap: 30px;
  }
  .paymentMethod {
    border-radius: 10px;
    height: max-content;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    position: relative;

    svg {
      width: 100%;
      height: 100%;
      min-width: 300px;
      min-height: 200px;
    }
    img {
      position: absolute;
      top: 10%;
      right: 10%;
    }
    .cardNumber {
      position: absolute;
      top: 40%;
      left: 10%;
      .label {
        font-size: 0.8rem;
        color: rgb(57, 52, 52);
      }
      p {
        gap: 5px;
        display: flex;
        align-items: center;
        font-size: 1.8rem;
        font-weight: bold;
        margin: 0;
        span {
          &:first-of-type {
            padding-top: 10px;
          }
        }
      }
    }
    .expDate {
      position: absolute;
      top: 80%;
      left: 10%;
      display: flex;
      flex-direction: column;
      gap: 5px;
      span {
        &:last-of-type {
          font-size: 1.1rem;
          font-weight: bold;
        }
      }
    }
  }
}
