@import "../../../_styles/variables";
.wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  .row {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 20px;
    @media only screen and (min-width: 1220px) {
      flex-direction: row;
      padding: 10px 0;
    }
    .column {
      padding: 12px;
      background: $white;
      box-shadow: 0px 2px 6px #0000000a;
      border-bottom: 2px solid $lightGray;
      border-right: 2px solid $lightGray;

      border-radius: 4px;
      width: 100%;
      display: flex;
      flex-direction: column;
      div {
        padding: 10px 0;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        padding-left: 30px;
        height: 49px;
      }
      .spanTitle {
        font-size: 0.9rem;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        gap: 10px;
        .date {
          color: $link-default;
        }
      }
      .spanText {
        padding: 10px 0;
        font-family: $font-primary-bold;
        font-size: 1.5rem;
      }
    }
  }
  .filter {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 20px;
    padding: 10px 0;

    @media only screen and (min-width: 992px) {
      flex-direction: column;
      justify-content: center;
    }
    @media only screen and (min-width: 1500px) {
      flex-direction: row;
      justify-content: flex-end;
    }
    .selects {
      display: grid;
      grid-template-columns: 1fr;
      gap: 20px;
      .selectDate,
      .selectStatus,
      .selectType {
        width: 100%;
      }
      @media only screen and (min-width: 400px) {
        grid-template-columns: 1fr 1fr;
      }
      @media only screen and (min-width: 700px) {
        display: flex;
        flex-direction: row;
      }
      @media only screen and (min-width: 1300px) {
        .selectDate,
        .selectStatus {
          width: 120px;
        }
        .selectType {
          width: 150px;
        }
      }
    }
    .searchAndDownload {
      width: 100%;
      display: flex;
      justify-content: space-between;
      gap: 10px;
      align-items: center;
      flex-direction: row;
      .searchBar {
        width: 100%;
      }
      .downloadButton {
        button {
          margin: 0;
        }
      }
    }
  }
  .pagination {
    padding-top: 15px;
    margin-top: auto;
  }
}

.header {
  position: relative;
  padding: 0 !important;
  width: max-content;
  height: unset !important;
}
.help {
  position: absolute;
  padding: 0 !important;
  height: unset !important;

  top: -3px;
  right: -20px;
  svg {
    width: 15px;
    height: 15px;
  }
}
.tooltip {
  height: unset !important;
  z-index: 2;
  padding: 10px 12px !important;
  background-color: #4a5568;
  color: white;
  position: absolute;
  width: 260px;
  right: -270px;
  top: 15px;
  border-radius: 10px;
}
.tooltip_left {
  height: unset !important;
  z-index: 2;
  padding: 10px 12px !important;
  background-color: #4a5568;
  color: white;
  position: absolute;
  width: 255px;
  right: -270px;
  top: 15px;
  border-radius: 10px;
  @media only screen and (min-width: 1220px) {
    right: -5px;
  }
}
