@import "../../../../_styles/variables";

.wrapper {
  display: flex;
  flex-direction: column;
  padding: 1.5rem;
  background-color: #f9f9f9;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  max-width: 500px;
  margin: 1rem auto;

  h3 {
    margin-bottom: 1rem;
    font-size: 1.5rem;
    color: #333;
    text-align: center;
  }

  .details {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  .row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.5rem 0;
    border-bottom: 1px solid #e0e0e0;

    &:last-child {
      border-bottom: none;
    }

    p {
      margin: 0;
      font-size: 0.9rem;
      color: #555;

      &.header {
        font-weight: bold;
        color: #333;
      }
    }
  }
  .actionButton {
    margin-top: 1rem;
    padding: 0.75rem;
    background-color: $glacier;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 1rem;

    &:hover {
      background-color: $dark-glacier;
    }
  }

  .confirmationRow {
    margin-top: 1rem;
    padding: 1rem;
    background-color: #fff;
    border: 1px solid #e0e0e0;
    border-radius: 4px;
    text-align: center;

    p {
      margin-bottom: 0.5rem;
      font-size: 1rem;
      color: #333;
    }

    .confirmationButtons {
      display: flex;
      justify-content: center;
      gap: 1rem;
      button {
        margin-bottom: 0;
      }
    }

    .confirmButton {
      padding: 0.5rem 1rem;
      background-color: $glacier;
      color: #fff;
      border: none;
      border-radius: 4px;
      cursor: pointer;

      &:hover {
        background-color: $dark-glacier;
      }
    }

    .cancelButton {
      padding: 0.5rem 1rem;
      background-color: $terracotta;
      color: #fff;
      border: none;
      border-radius: 4px;
      cursor: pointer;

      &:hover {
        background-color: $terracotta-dark;
      }
    }
  }
}
