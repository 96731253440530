.custom-table {
  overflow: auto;
  max-height: 700px;
  margin-bottom: 20px;

  &::-webkit-scrollbar {
    width: 7px;
    height: 7px;
    padding: 5px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 7px;
    --webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
    background: $casper;
  }
  .tb-head-th th {
    border-bottom: 2px solid $color-secondary;
  }

  .tb-10w {
    min-width: 10px !important;
    width: 10px;
  }

  .tb-50w {
    min-width: 50px !important;
  }

  table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0;

    tr {
      border-bottom: 1px solid $table-border;
    }

    th {
      text-align: start;
      padding: 10px 0;
      min-width: 120px;
      position: sticky;
      top: 0;
    }

    td {
      word-break: break-word;
      padding: 10px 0;
      min-width: 120px;
      cursor: pointer;
      border-bottom: 1px solid $table-border;
      font-size: 13px;
      text-overflow: ellipsis;
      white-space: nowrap;
      span {
        display: flex;
      }
    }
  }
}
