@import "../../_styles/variables";

.label {
  display: flex;
  justify-content: space-between;
  gap: 10px;
  flex-direction: column;
  margin: auto;
  p {
    margin-top: unset;
  }
  @media only screen and (min-width: 700px) {
    width: 415px;
    flex-direction: row;
    p {
      margin-top: 0;
    }
  }
  @media only screen and (min-width: 1300px) {
    width: unset;
  }
  .fromTo {
    display: flex;
    flex-direction: column;
    justify-content: center;
    div div {
      margin-bottom: 5px;
      margin-top: 0;
      input {
        padding: 10px;
      }
    }
    @media only screen and (min-width: 700px) {
      justify-content: flex-end;
    }
    @media only screen and (min-width: 400px) {
      gap: 30px;
      flex-direction: row;
    }
    input {
      width: 140px;
    }
  }
}

.fromTo {
  display: flex;
  flex-direction: column;
  justify-content: center;
  div div {
    margin-bottom: 5px;
    margin-top: 0;
    input {
      padding: 10px;
    }
  }
  @media only screen and (min-width: 700px) {
    justify-content: flex-end;
  }
  @media only screen and (min-width: 400px) {
    gap: 30px;
    flex-direction: row;
  }
  input {
    width: 140px;
  }
}
