@import "./../../_styles/_variables";

.wrapper {
  text-align: center;
  .channels {
    margin-bottom: 20px;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    align-items: center;
    gap: 20px;
    padding: 15px 0;
    @media only screen and (min-width: 700px) {
      grid-template-columns: repeat(2, 1fr);
      justify-content: center;
    }
    @media only screen and (min-width: 900px) {
      grid-template-columns: repeat(3, 1fr);
      justify-content: center;
    }
    .channel {
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: left;
      width: 200px;
      gap: 20px;
      margin: auto;
      border-radius: 30px;
      &:hover {
        background-color: $botticelli;
      }
      img {
        width: 60px;
        height: 60px;
        border-radius: 50%;
        object-fit: cover;
      }
      .icon {
        width: 60px;
        height: 60px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        border: 1px solid $black;

        svg {
          width: 45px;
          height: 30px;
        }
      }
    }
    .selected {
      background-color: $botticelli;
    }
  }
  hr {
    color: $botticelli;
    border: 1px solid $botticelli;
  }
  .addNew {
    .form {
      align-items: center;
      display: flex;
      flex-direction: column;
      padding-top: 15px;
      .content {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-direction: column;
        align-items: center;
        @media only screen and (min-width: 700px) {
          flex-direction: row;
          gap: 20px;
        }
        .inputs {
          display: flex;
          flex-direction: column;
          .selectRegion {
            margin-bottom: 20px;
          }
        }
      }

      .buttons {
        display: flex;
        justify-content: center;
        gap: 20px;
        margin-top: 20px;
        width: 90%;
        align-items: center;
        flex-direction: column;

        @media only screen and (min-width: 700px) {
          flex-direction: row;
        }
      }
    }
  }
}
