@import '../../../_styles/variables';
.tabs {
  display: flex;
  flex-direction: row;
  gap: 10px;
  margin-bottom: 20px;

  div {
    cursor: pointer;
  }
   

  .tabActive {
    color: $color-text;
    font-weight: bold;
    display: flex;
    padding: 5px 10px;
    text-transform: capitalize;
    border-bottom: 2px solid $border-active;
    margin-left: 10px;
  }

  .tabInactive {
    font-weight: bold;
    display: flex;
    padding: 5px 10px;
    text-transform: capitalize;
    color: $link-default;
    margin-left: 10px;

    &:hover {
      color: $color-text;
      border-bottom: 2px solid $border-active;
    }
  }
}