@import "../../../../_styles/variables";

.wrapper {
  display: flex;
  flex-direction: column;
  @media only screen and (min-width: 1300px) {
    flex-direction: row;
  }
  justify-content: space-between;
  gap: 20px;
  // overflow: hidden;
  height: 100%;

  .left,
  .right {
    border: 1px solid #eaeaea;
    border-radius: 8px;
    width: 100%;
    @media only screen and (min-width: 1300px) {
      width: 50%;
    }
    height: 600px;
    display: flex;
    flex-direction: column;
  }
  .left {
    .header {
      padding: 10px;

      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      button {
        margin: 0;
      }
      .info {
        display: flex;
        flex-direction: row;
        height: 60px;
        padding: 10px;
        gap: 10px;
        img {
          height: 30px;
        }
        div {
          padding: 5px 0;
        }
        svg {
          height: 30px;
        }
        span {
          font-size: 30px;
          font-weight: bold;
        }
      }
    }
    .searchAndFilter {
      padding: 0 10px 10px;

      width: 100%;
      div {
        max-width: unset;
      }
    }
    .tableWrapper {
      overflow: auto;
      height: 100%;
      display: flex;
      flex-direction: column;
    }
  }
}
