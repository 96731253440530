@import "./../../../../_styles/variables";

.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .header {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;

    p {
      font-size: 1.125rem;
      cursor: pointer;
      font-family: $font-primary;
    }

    .tabActive {
      color: $color-text;
      font-weight: bold;
      display: flex;
      padding: 5px 10px;
      margin-left: 15px;
      text-transform: capitalize;
      border-bottom: 2px solid $border-active;
    }

    .tabInactive {
      font-weight: bold;
      display: flex;
      padding: 5px 10px;
      margin-left: 15px;
      text-transform: capitalize;
      color: $link-default;

      &:hover {
        color: $color-text;
        border-bottom: 2px solid $border-active;
      }
    }
  }

  .contentWrapper {
    display: flex;

    .center {
      width: 100%;
    }
  }

  @media only screen and (min-width: 600px) {
    .header {
      flex-direction: row;
    }
  }
}

.loading {
  text-align: center;
  padding: 53px 0;
}
