.wrapper {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;

    div {
        padding: 0;
    }

    img {
        width: 100px;
        height: 100px;
        border-radius: 50%;
        object-fit: cover;
    }

    .imgHidden {
        @extend img;
        display: none;
    }
}

.large {
    @extend .wrapper;
}

.medium {
    @extend .wrapper;

    &,
    img {
        width: 70px;
        height: 70px;
    }
}

.small {
    @extend .wrapper;

    &,
    img {
        height: 50px;
        width: 50px;
    }
}