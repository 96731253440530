@import "../../../_styles/variables";

.wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: white;
  border-radius: 10px;
  padding: 20px;
  h2 {
    text-align: left;
    margin: 25px 0 20px;
  }
  .wideBox,
  .imgBox {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 120px;
    border: 1px solid #eaeaea;
    box-shadow: 0px 2px 6px #0000000a;
    border-radius: 8px;
    padding: 15px;
  }
  .wideBox {
    gap: 10px;
    p {
      margin: 0;
    }
  }
  .p {
    margin: 0;
    font-size: 14px;
    color: #4a5568;
    margin-top: 15px;
  }
  .large {
    margin: 0;
    margin-top: 15px;
    font-size: 36px;
    font-weight: bold;
    color: #000000;
  }
  .imgBox {
    position: relative;
    height: 200px;
    svg {
      height: 54px;
      width: unset;
    }
  }
  .container {
    background-color: $botticelli;
    padding: 20px;
    border-radius: 30px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    font-size: 20px;
    margin-top: 10px;
  }
  .row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
    flex-direction: row;
  }
  .loader {
    div {
      padding: 10px 0;
      svg {
        width: 30px;
        height: 30px;
      }
    }
  }
  .p {
    position: relative;
  }
  .help {
    position: absolute;
    top: -3px;
    right: -20px;
    svg {
      width: 15px;
      height: 15px;
    }
  }
  .tooltip {
    z-index: 2;
    padding: 15px;
    background-color: #4a5568;
    color: white;
    position: absolute;
    width: 250px;
    right: -270px;
    bottom: 25px;
    border-radius: 10px;
  }
  .tooltip_left {
    z-index: 2;
    padding: 15px;
    background-color: #4a5568;
    color: white;
    position: absolute;
    width: 250px;
    right: -5px;
    bottom: 25px;
    border-radius: 10px;
  }
}
