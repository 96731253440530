@import "../../../../_styles/variables";

.wrapper {
  display: flex;
  flex-direction: column;
  @media only screen and (min-width: 992px) {
    margin: 20px 0;
  }
  @media only screen and (min-width: 1200px) {
    margin: 0 0 20px;
  }
  .item,
  .currently {
    padding: 15px;
    margin-top: 0;
    background: $white;
    box-shadow: 0px 2px 6px #0000000a;
    border-radius: 4px;
    position: relative;
    min-width: 125px;

    h3 {
      font-size: 2.1875rem;
      margin: 5px;
    }

    span {
      color: $color-neutral;
      font-family: $font-primary-bold;
      font-size: 1.125rem;
    }
    img {
      width: 52px;
      height: 40px;
      position: absolute;
      top: 10px;
      right: 10px;
    }

    & > div {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }

  .currently {
    height: 140px;
  }

  @media only screen and (min-width: 1600px) {
    .dailyAndMonthly {
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      .item {
        max-width: 225px;
      }
    }

    .currently {
      padding-top: 20px;
      margin-top: 0;
      img {
        position: absolute;
        top: 20px;
        right: 20px;
      }
    }
  }
}
