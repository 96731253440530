@import "../../../../_styles/variables";

.wrapper {
  padding: 20px 0;
  height: max-content;

  .options {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 20px;
    margin-bottom: 30px;
    @media only screen and (min-width: 992px) {
      margin-bottom: 0px;
    }
  }
  .qrCode {
    margin: auto;
    width: max-content;
    img {
      width: 100px;
      height: 100px;
    }
  }
  .content {
    height: max-content;
    textarea {
      height: 100px;
    }

    .buttons {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 20px;
      gap: 10px;
      flex-direction: column;
      @media only screen and (min-width: 600px) {
        flex-direction: row;
      }
      @media only screen and (min-width: 992px) {
        flex-direction: column;
      }
      @media only screen and (min-width: 1450px) {
        flex-direction: row;
      }
      button {
        margin-bottom: 0;
      }
    }
    .workingTime {
      text-align: center;
      width: 100%;
      margin: 0 auto;

      .title {
        padding: 0 20px 0 10px;
        border-radius: 4px;
        background-color: $botticelli;
        display: flex;
        justify-content: space-between;
        &:hover {
          cursor: pointer;
        }
      }
      .content {
        p {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
        }
      }
    }
  }
  .cleaningQr {
    margin-top: 20px;
  }
  .details {
    .header {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }
    .containers {
      width: 100%;
      margin: 0 auto;
      .selectors {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        gap: 20px;
        align-items: flex-end;
        div {
          margin-bottom: 0;
        }
        .sort {
          width: 150px;
          margin-bottom: 0;
          div {
            margin-bottom: 0;
          }
        }
      }
      .content {
        p {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          padding-bottom: 5px;
          border-bottom: 2px solid $botticelli;
        }
      }
    }
  }
  .map {
    height: 250px;
    margin-top: 30px;
    border-radius: 3px;
    border: 2px solid black;
    display: flex;
  }
}
.noData {
  text-align: center;
  padding: 50px 0;

  span {
    font-size: 1.188rem;
    color: $link-default;
    font-family: $font-primary-bold;
  }

  @media only screen and (min-width: 992px) {
    padding: 200px 0;
  }
}

.draft {
  display: grid;
  place-items: center;
  font-size: 1.188rem;
  font-weight: bold;
  color: $terracotta;
  padding: 20px;
}
