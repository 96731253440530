.wrapper {
  text-align: center;
  p {
    font-size: 1.2rem;
  }
  .buttons {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 10px;
  }
}
