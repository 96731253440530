@import "../../../_styles/variables";

.wrapper {
    display: flex;
    flex-direction: column;
    padding: 20px;
    height: calc(100% - 70px);
    gap: 5px;

    @media only screen and (min-width: 500px) {
        padding: 0 20px;
    }

    h2 {
        color: $color-text;
    }

    .left,
    .right {
        &::-webkit-scrollbar {
            width: 7px;
            height: 7px;
            padding: 5px;
        }

        &::-webkit-scrollbar-thumb {
            border-radius: 7px;
            --webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
            background: $casper;
        }
       
    }
    .right{
         .selector{
            margin-bottom: 50px;
        }
    }
    .left {
        padding: 20px;
        box-shadow: 0px 2px 6px #0000000a;
        border-radius: 4px;

        .header {
            display: flex;

            p {
                font-size: 1.125rem;
                font-family: $font-primary;
                cursor: pointer;
            }

            .tabActive {
                color: $color-text;
                font-weight: bold;
                display: flex;
                padding: 5px 10px;
                text-transform: capitalize;
                border-bottom: 2px solid $border-active;
            }

            .tabInactive {
                font-weight: bold;
                display: flex;
                text-transform: capitalize;
                color: $link-default;
                padding: 5px 10px;

                &:hover {
                    color: $color-text;
                    border-bottom: 2px solid $border-active;
                }
            }
        }

        table {

            thead,
            tbody {

                th,
                td {
                    &:first-child {
                        padding: 10px 5px;
                    }
                }
            }

            tbody {
                tr {
                    &:hover {
                        background-color: $botticelli;
                        cursor: pointer;
                    }
                }
            }
        }

        .selected {
            background-color: $botticelli;
        }
    }

    .right {
        box-shadow: 0px 2px 6px #0000000a;
        border-radius: 4px;
        display: flex;
        flex-direction: column;
        align-items: center;

        .error {
            p {
                color: $terracotta;
                text-align: center;
            }
        }

        form {
            display: flex;
            flex-direction: column;
            width: 100%;

            .content {
                display: flex;
                flex-direction: column;
                align-self: center;
                width: 100%;
                margin-bottom: 70px;
            }

            .options {
                margin-top: 30px;

                &>div {
                    display: flex;
                    justify-content: space-around;
                }

                button {
                    max-width: 70px;
                    margin-left: 15px;
                    margin-top: 5px;
                }
            }
        }

    }

    @media only screen and (min-width: 992px) {
        flex-direction: row;
        justify-content: space-between;

        .left {
            width: 60%;
            background: $white;
            overflow-y: auto;

            .header {
                .tabActive {
                    margin-left: 25px;
                    padding: 5px 20px;
                }

                .tabInactive {
                    margin-left: 25px;
                    padding: 5px 20px;
                }
            }
        }

        .right {
            width: 40%;
            background: $white;
            position: relative;
            overflow-y: auto;
            overflow-x: hidden;
            padding: 15px;

            .content {
                padding: 5px;
            }

            .options {
                button {
                    max-width: 100px !important;
                }

                &>div {
                    display: flex;
                    justify-content: center;
                }

                .submit {
                    display: flex;
                    justify-content: center;
                }
            }
        }
    }

    @media only screen and (min-width: 1300px) {}
}