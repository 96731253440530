@import "../../../src/_styles/variables";

.wrapper {
  margin-top: 20px;
  display: flex;
  flex-direction: column-reverse;
  width: 100%;
  background-color: $white;

  .content {
    background-image: url("./../../_assets/images/reusablesImage.jpg");
    background-size: cover;
    background-position: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 100px 0px;

    img {
      width: 30%;
    }
  }

  .container {
    height: 100%;
    padding: 30px 5% 0 5%;
    margin: 0 auto;

    & > div {
      margin: 0 auto;
      text-align: center;
      padding-bottom: 20px;
      max-width: 530px;

      .logo {
        img {
          width: 200px;
          height: 45px;
        }
      }
      .backToLoginDiv {
        text-align: center;
        margin-top: 20px;
        a {
          border-bottom: 1px solid $gray;
        }
        a:hover {
          color: $gray;
        }
      }
      .info {
        font-size: 1rem;
        font-weight: 600;
        margin-top: 20px;
        letter-spacing: var(--unnamed-character-spacing-0);
        text-align: center;
        letter-spacing: 0px;
        color: #242424;
        opacity: 1;
      }

      .mailInfo {
        padding: 10px;
      }

      form {
        margin-top: 20px !important;
        margin: 40px auto 0 auto;
        max-width: 350px;
        display: flex;
        flex-direction: column;

        h2 {
          font-size: 3.313rem;
          text-align: center;
          letter-spacing: 0px;
          color: $black;
          opacity: 1;
          margin: 0;
          margin-bottom: 20px;
          font-family: $font-secondary;
        }

        .forgotPassword {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;
          padding-right: 10px;
          & > div > label {
            margin-bottom: 0;
          }

          .forgotPasswordLink {
            height: 20px;
          }
        }

        .backToLoginDiv {
          display: flex;
          flex-direction: row-reverse;
          margin-top: 10px;
          padding-right: 10px;
        }

        a {
          margin-left: 15px;
          color: $gray;
          font-family: $font-primary;
          font-weight: 500;
          font-size: 0.875rem;
          border-bottom: 1px solid $gray;
        }

        span {
          display: flex;
          align-items: center;
          font-size: 0.875rem;
          font-weight: 500;
          color: $gray;
        }

        button {
          margin-top: 20px;
          width: 100%;
        }
      }
      .links {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        gap: 20px;
        @media only screen and (min-width: 992px) {
          width: 350px;
          margin: auto;
        }
        h4 {
          color: $casal;
        }
        div {
          display: flex;
          justify-content: center;
          gap: 60px;
          .link {
            a {
              width: 100px;
              height: 100px;
              border-radius: 10px;
              img {
                width: 100px;
                height: 100px;
                border-radius: 10px;
              }
            }
          }
        }
      }
    }
  }

  @media only screen and (min-width: 800px) {
    .content {
      img {
        width: unset;
      }
    }
  }

  @media only screen and (min-width: 992px) {
    flex-direction: row;
    justify-content: space-around;
    margin-top: 0;

    .container {
      width: 50%;
      min-height: 100vh;
      padding: 0;
      display: flex;
      align-items: center;

      & > div {
        .info {
          font-size: 1.5rem;
          font-weight: bold;
        }

        form {
          h2 {
            margin-top: 20px;
            margin-bottom: 54px;
            font-size: 3.313rem;
          }

          button {
            margin-top: 40px;
          }

          .forgotPassword {
            & > div > label > span {
              margin-bottom: 0;
              margin-right: 0;
            }

            .forgotPasswordLink {
              height: 20px;
            }
          }
        }
      }
    }

    .content {
      width: 50%;
      padding: 0px;
    }
  }
}
