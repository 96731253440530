@import "../../../../_styles/variables";

.wrapper {
  margin-top: 50px;

  .buttonsWrapper {
    display: flex;
    justify-content: space-between;

    button {
      margin-top: 20px;
      margin-right: 10px;
    }
  }

  .paymentTitle {
    padding: 0;
    margin-top: 20px;
    text-align: center;
  }

  .selectYear {
    margin-top: 20px;
    display: flex;
    flex-direction: row-reverse;
  }
  .name {
    cursor: pointer;
  }
  .transactions {
    min-width: 230px;
    margin-top: 0;
    overflow-y: auto;
    background-color: $wild-sand;
    position: relative;

    .closeButton {
      position: absolute;
      top: 10px;
      right: -5px;
    }

    .titleBox {
      margin-top: 30px;
      border-bottom: 1px solid $botticelli;
      height: 50px;
    }

    .table {
      margin-top: 30px;
      overflow-x: auto;
      padding: 0 20px;

      h1 {
        padding-left: 0;
        border-bottom: 1px solid #a2c4d0;
        padding-bottom: 20px;
      }

      &::-webkit-scrollbar {
        width: 7px;
        height: 7px;
        padding: 5px;
      }

      &::-webkit-scrollbar-thumb {
        border-radius: 7px;
        --webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
        background: $casper;
      }

      table {
        margin-top: 30px;
        width: 100%;
        border-spacing: 0;
      }

      table caption {
        text-align: left;
        padding-bottom: 20px;
        font-size: 1rem;
        font-family: $font-primary-bold;
      }

      table thead {
        background-color: $casper;
        color: $white;
      }

      table thead tr th {
        padding: 10px;
      }

      table thead tr th:first-child {
        text-align: left;
      }

      table thead tr th:last-child {
        text-align: right;
      }

      table tbody tr td {
        font-size: 0.875rem;
        font-weight: 500;
        padding: 10px;
        text-align: center;
      }

      table tbody tr td:first-child {
        text-align: left;
      }

      table tbody tr td:last-child {
        text-align: right;
      }
    }

    .priceRow {
      display: flex;
      flex-direction: column;
      padding: 10px;
      align-content: end;
      margin-top: 20px;

      div {
        width: max-content;
        margin-left: auto;

        .totalPrice {
          padding-bottom: 4px;
          font-size: 1rem;
          display: flex;
          text-align: right;
          justify-content: space-between;

          &:last-child {
            border-top: 1px solid $casal;
            padding-top: 5px;
          }

          span {
            display: inline-block;
            width: 70px;
            text-align: left;
          }
        }
      }
    }

    .date {
      margin-top: 50px;
      height: 100px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-start;

      .createdDate p {
        margin: 0;
      }

      & > div {
        display: flex;
        flex-direction: row-reverse;

        .signatureLine {
          height: 20px;
          width: 100px;
          border-bottom: 1px solid $botticelli;
        }
      }
    }
  }

  @media only screen and (min-width: 1281px) {
    .buttonsWrapper {
      button {
        margin-top: 0;
      }
    }

    /* table from monthlyDetails */
    .transactions {
      .closeButton {
        margin-right: 18px;
      }

      .table {
        padding: 0 20px;
      }

      .date {
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        p {
          margin: 0;
        }

        & > div {
          display: flex;
          flex-direction: column;
          align-items: center;

          .signatureLine {
            height: 35px;
          }
        }
      }
    }
  }
}
