@import "../../_styles/variables";

.wrapper {
  display: flex;
  flex-direction: column;
  gap: 1px;
  padding: 20px;

  .left,
  .right {
    background-color: $white;
    width: 100%;
    box-shadow: 0px 2px 6px #0000000a;
    border-radius: 4px;
  }

  @media only screen and (min-width: 500px) {
    padding: 0 20px;

    .right {
      padding: 0 20px;
    }
  }

  @media only screen and (min-width: 992px) {
    flex-direction: row;

    .left,
    .right {
      overflow: auto;

      &::-webkit-scrollbar {
        width: 7px;
        height: 7px;
        padding: 5px;
      }

      &::-webkit-scrollbar-thumb {
        border-radius: 7px;
        --webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
        background: $casper;
      }
    }

    .left {
      width: 55%;
    }

    .right {
      width: 45%;
    }
  }
}
