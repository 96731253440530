@import "../../../../_styles/variables";

.wrapper {
  //height: 100%;
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 20px;
  overflow: hidden;
  @media only screen and (min-width: 1300px) {
    flex-direction: row;
    max-height: 600px;
  }
  @media only screen and (min-width: 1600px) {
    max-height: 700px;
  }
  .left {
    width: 60%;
    padding: 0 30px;
    @media only screen and (max-width: 1300px) {
      width: 90%;
      display: flex;
      flex-direction: column;
      margin: auto;
    }
  }
  .right {
    width: 40%;
    height: 100%;
    @media only screen and (max-width: 1300px) {
      width: 90%;
      display: flex;
      flex-direction: column;
      margin: auto;
    }
    .date {
      display: flex;
      flex-direction: row;
      align-items: center;
      width: 80%;
      margin: auto;
      margin-bottom: 10px;
      padding-bottom: 5px;

      .datepicker {
        width: 180px;
        span {
          font-size: 16px;
        }
        div {
          input {
            width: 150px;
          }
        }
      }
    }
    .popularity {
      height: 70%;
      overflow: auto;
      margin: auto;
      width: 80%;
      border: 1px solid #c8c8c8;
      border-radius: 10px;
      padding: 20px 35px;
      .popularityRow {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        font-weight: 600;
        .name {
          color: #676767;
          font-weight: 500;
        }
      }
    }
    .noData {
      text-align: center;
      font-size: 1.188rem;
      color: $link-default;
      font-family: $font-primary-bold;
    }
  }
  h3 {
    text-align: center;
  }
  .header {
    margin-bottom: 20px;
    .selectDisabled {
      cursor: not-allowed;
      opacity: 0.5;
    }
    .row {
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      margin-top: 10px;
      gap: 10px;

      .selectDate {
        width: 320px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        .checkboxMonthly {
          label {
            margin-bottom: 0;
          }
        }
        .selectMonth,
        .checkboxMonthly {
          width: 115px;
          div {
            margin-bottom: 0;
          }
        }
        .selectYear {
          width: 80px;
          div {
            margin-bottom: 0;
          }
        }
        .selectPeriod {
          width: 100px;
          div {
            margin-bottom: 0;
          }
        }
        .selectDisabled {
          cursor: not-allowed;
          opacity: 0.5;
        }
      }
    }
    .icon {
      span {
        font-size: 0.875rem;
        font-family: $font-primary-medium;
        margin-left: 5px;
      }

      div {
        span:nth-of-type(1) {
          font-size: 2rem;
          color: unset;
        }

        img {
          margin-left: 15px;
        }
      }
    }

    @media only screen and (min-width: 560px) {
      .row {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
      }

      .icon {
        span {
          font-size: 1rem;
          font-family: $font-primary-medium;
        }

        div {
          display: inline-block;

          span:nth-of-type(1) {
            font-size: 2rem;
          }

          img {
            margin-left: 15px;
          }
        }
      }
    }

    @media only screen and (min-width: 992px) and (max-width: 1350px) {
      .row {
        flex-direction: column;
        align-items: flex-start;
        gap: 10px;
      }
    }

    @media only screen and (min-width: 992px) {
      .icon {
        div {
          span:nth-of-type(1) {
            font-size: 2rem;
          }
        }
      }
    }
  }
  .bigChartSection {
    position: relative;
  }
  .selectLocationMsg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    padding: 20px;
    border-radius: 10px;
    color: white;
    background-color: rgba(255, 0, 55, 0.76);
    border: 1px solid rgba(255, 225, 232, 0.5);
  }
}
